import { useTranslation } from "react-i18next";

function PageTitle({ title }) {
  // console.log(dir);

  const { t, i18n } = useTranslation();
  var dir = "ltr";
  dir = t("dir");
  return (
    <div
      className="flex flex-col p-5"
      style={{ backgroundImage: `url('/images/istatistik-zemin.png')` }}
    >
      <h1
        dir={dir} // Set the text direction directly
        className={`flex font-bold mt-3 text-3xl text-customPink`}
      >
        {title[t("lang")]}
      </h1>
    </div>
  );
}
export default PageTitle;
