import PageLayout from "../PageLayout";
import FirstCarsoul from "./topCarsoul";
import Shortcuts from "./shortcuts";
import Campagins from "./Campagins";
import PromotionFilms from "./PromotionFilms";
import Statistics from "./statistics";
import SaidThat from "./SaidThat";

function Home() {
  return (
    <>
      <FirstCarsoul />
      <Shortcuts />
      <Campagins />
      <PromotionFilms />
      <SaidThat />
      <Statistics
        projects={500}
        activeProjects={1200}
        benfits={96}
        volunteers={547}
        supported={4842}
      />
    </>
  );
}

export default Home;
