import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import api from "../../api.json";

function Footer() {
  const { t, i18n } = useTranslation();
  const [props, setProps] = useState({ content: [], copyrights: "" });

  useEffect(() => {
    // Replace `${api.url}` with the actual API URL or variable
    fetch(`${api.url}/get/footer`)
      .then((response) => response.json())
      .then((data) => {
        setProps(data);
      })
      .catch((error) => {
        console.error("Error fetching footer data: footer", error);
      });
  }, []); // Empty dependency array ensures this runs only once on component mount

  return (
    <footer
      className="footer bg-base-200 text-base-content p-10"
      style={{ direction: t("dir") }}
    >
      <aside>
        {/* logo */}
        <a href="/">
          <img
            src={`/images/logo_${i18n.language}.svg`}
            style={{ maxWidth: 140 }}
            alt="Logo"
          />
        </a>
        <p style={{ color: "#f7a01a" }}>{t(props.copyrights)}</p>
      </aside>

      {/* map each content group */}
      {props.content.map((group, index) => (
        <nav key={index}>
          <h6 className="footer-title" style={{ color: "#ad0372" }}>
            {group.title[t("lang")]}
          </h6>
          {/* map each group links */}
          {group.links.map((link, idx) => (
            <a
              key={idx}
              href={link.route}
              className="link link-hover"
              style={{ color: "#240905" }}
            >
              {t(link.title[t("lang")])}
            </a>
          ))}
        </nav>
      ))}

      <div
        className="lg:hidden md:hidden"
        style={{ width: "100vw", height: "10vh" }}
      ></div>
    </footer>
  );
}

export default Footer;
