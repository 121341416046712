import api from "../../api.json";
import React, { useEffect, useState } from "react";
import EditorCTranslator from "./EditorComponentTranslator";
import HomeEditorCTranslator from "./HomeEditorComponentTranslator";

function PageTitleEditor({ data, setData, onSave }) {
  try {
    return (
      <div className="mt-3">
        <h1>Title</h1>
        {data ? (
          <div className="container " style={{ minWidth: "35vw" }}>
            <div className="flex flex-col items-start p-2 rounded-md bg-slate-100 shadow-xl min-w-full space-y-3">
              <h1 className=" ">Arabic</h1>
              <input
                value={data.title.ar}
                onChange={(e) =>
                  setData({
                    ...data,
                    title: { ...data.title, ar: e.target.value },
                  })
                }
                className="textarea textarea-bordered textarea-md w-full "
              ></input>
              <h1> English</h1>
              <input
                value={data.title.en}
                onChange={(e) =>
                  setData({
                    ...data,
                    title: { ...data.title, en: e.target.value },
                  })
                }
                className="textarea textarea-bordered textarea-md w-full "
              ></input>

              <h1> Turkish</h1>
              <input
                value={data.title.tr}
                onChange={(e) =>
                  setData({
                    ...data,
                    title: { ...data.title, tr: e.target.value },
                  })
                }
                className="textarea textarea-bordered textarea-md w-full "
              ></input>

              <button
                class="btn btn-wide self-center hover:bg-customPink hover:text-white"
                onClick={onSave}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );
  } catch (e) {
    console.log(e);
  }
}

function ComponentItem({ pageName, name, onSelect, refresh }) {
  const fetchData = async () => {
    try {
      const response = await fetch(`${api.url}/get/${pageName}`);
      const result = await response.json();
      refresh(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelect = async () => {
    function generateRandomId() {
      const chars = "0123456789abcdef";
      let id = "";
      for (let i = 0; i < 24; i++) {
        id += chars[Math.floor(Math.random() * chars.length)];
      }
      return id;
    }

    try {
      var jsonBody = {};
      switch (name) {
        case "Text": {
          jsonBody = {
            id: generateRandomId(),
            type: "text",
            color: "text-amber-900",
            hoverColor: "",
            layout: "",
            size: "",
            content: {
              ar: "نص عينة",
              en: "sample Text ",
              tr: "Sample Text in Turkish",
            },
            weight: "font-lg",
            isLink: false,
            link: "",
            children: [],
          };
          break;
        }

        case "Article": {
          jsonBody = {
            id: generateRandomId(),
            type: "simple-article",
            background: "/images/kampanya_bg.png",
            layout: "",
            spacing: "space-y-2",
            children: [
              {
                id: generateRandomId(),
                type: "text",
                color: "text-customPink",
                hoverColor: "",
                layout: "",
                size: "text-2xl",
                content: {
                  ar: " عنوان",
                  en: "Title ",
                  tr: "Sample Title in Turkish",
                },
                weight: "font-lg",
                isLink: false,
                link: "",
                children: [],
              },
              {
                id: generateRandomId(),
                type: "text",
                color: "text-customOrange",
                hoverColor: "",
                layout: "",
                size: "",
                content: {
                  ar: "في عالمٍ يزداد تعقيدًا، أصبحت التكنولوجيا جزءًا لا يتجزأ من حياتنا اليومية. لقد غيرت الطريقة التي نعيش بها، ونعمل، ونتواصل مع الآخرين. سواءً كان ذلك من خلال الهواتف الذكية التي نحملها في جيوبنا، أو الأنظمة الذكية التي تدير منازلنا، فإن التكنولوجيا تساهم بشكل كبير في تحسين حياتنا وتسهيل مهامنا اليومية. ولكن مع هذه الفوائد، تأتي تحديات جديدة تتطلب منا أن نكون أكثر وعيًا وإدراكًا لتأثيرات التكنولوجيا على حياتنا.",
                  en: "In an increasingly complex world, technology has become an integral part of our daily lives. It has changed the way we live, work, and communicate with others. Whether through the smartphones we carry in our pockets or the smart systems that manage our homes, technology plays a significant role in improving our lives and making our daily tasks easier. But with these benefits come new challenges that require us to be more aware and conscious of technology's impact on our lives. ",
                  tr: "Giderek karmaşıklaşan bir dünyada, teknoloji günlük hayatımızın ayrılmaz bir parçası haline geldi. Yaşam şeklimizi, çalışma tarzımızı ve başkalarıyla iletişim kurma yöntemlerimizi değiştirdi. Cebimizde taşıdığımız akıllı telefonlar ya da evlerimizi yöneten akıllı sistemler aracılığıyla, teknoloji hayatımızı iyileştirmede ve günlük görevlerimizi kolaylaştırmada önemli bir rol oynuyor. Ancak bu faydalarla birlikte, teknolojinin hayatımız üzerindeki etkisinin daha fazla farkında olmamızı gerektiren yeni zorluklar da ortaya çıkıyor.",
                },
                weight: "font-lg",
                isLink: false,
                link: "",
                children: [],
              },
            ],
          };
          break;
        }

        case "Article With Image": {
          jsonBody = {
            id: generateRandomId(),
            type: "article-with-img",
            background: "",
            img: {
              en: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
              ar: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
              tr: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
            },
            children: [
              {
                id: generateRandomId(),
                type: "text",
                color: "text-customPink",
                hoverColor: "",
                layout: "",
                size: "text-2xl",
                content: {
                  ar: " عنوان",
                  en: "Title ",
                  tr: "Sample Title in Turkish",
                },
                weight: "font-lg",
                isLink: false,
                link: "",
                children: [],
              },
              {
                id: generateRandomId(),
                type: "text",
                color: "text-customOrange",
                hoverColor: "",
                layout: "",
                size: "",
                content: {
                  ar: "في عالمٍ يزداد تعقيدًا، أصبحت التكنولوجيا جزءًا لا يتجزأ من حياتنا اليومية. لقد غيرت الطريقة التي نعيش بها، ونعمل، ونتواصل مع الآخرين. سواءً كان ذلك من خلال الهواتف الذكية التي نحملها في جيوبنا، أو الأنظمة الذكية التي تدير منازلنا، فإن التكنولوجيا تساهم بشكل كبير في تحسين حياتنا وتسهيل مهامنا اليومية. ولكن مع هذه الفوائد، تأتي تحديات جديدة تتطلب منا أن نكون أكثر وعيًا وإدراكًا لتأثيرات التكنولوجيا على حياتنا.",
                  en: "In an increasingly complex world, technology has become an integral part of our daily lives. It has changed the way we live, work, and communicate with others. Whether through the smartphones we carry in our pockets or the smart systems that manage our homes, technology plays a significant role in improving our lives and making our daily tasks easier. But with these benefits come new challenges that require us to be more aware and conscious of technology's impact on our lives. ",
                  tr: "Giderek karmaşıklaşan bir dünyada, teknoloji günlük hayatımızın ayrılmaz bir parçası haline geldi. Yaşam şeklimizi, çalışma tarzımızı ve başkalarıyla iletişim kurma yöntemlerimizi değiştirdi. Cebimizde taşıdığımız akıllı telefonlar ya da evlerimizi yöneten akıllı sistemler aracılığıyla, teknoloji hayatımızı iyileştirmede ve günlük görevlerimizi kolaylaştırmada önemli bir rol oynuyor. Ancak bu faydalarla birlikte, teknolojinin hayatımız üzerindeki etkisinin daha fazla farkında olmamızı gerektiren yeni zorluklar da ortaya çıkıyor.",
                },
                weight: "font-lg",
                isLink: false,
                link: "",
                children: [],
              },
            ],
          };
          break;
        }

        case "Project Wrapper": {
          jsonBody = {
            id: generateRandomId(),
            type: "card-wrapper",
            title: "Projects Example",
            children: [],
          };
          break;
        }

        case "Project": {
          jsonBody = {
            id: generateRandomId(),
            img: {
              en: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
              ar: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
              tr: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
            },
            type: "project-card",
            title: {ar:"اسم المشروع",en:"ProjectTitle",tr:"ProjectTitle in turkish"},
            hoverColor: "bg-customOrange",
            overlayColor: "bg-black",
            route: "/",
          };
          break;
        }

        case "people": {
          jsonBody = {
            id: generateRandomId(),
            type: "people",
            people: [
              {
                img: "/images/people.webp",
                name: "amr basem in tr",
                country: "Agypten",
                details: "hello proj2",
              },
            ],
          };
          break;
        }

        case "TimeLine": {
          jsonBody = {
            id: generateRandomId(),
            type: "timeline",
            title: {
              ar: "عنوان المخطط",
              en: "TimeLine Title",
              tr: "TimeLine Title in turkish",
            },
            data: [
              {
                ar: "الانجاز الاول",
                en: "progress1",
                tr: "progress1 in turkish",
              },
            ],
          };
          break;
        }

        default: {
          console.error("Unknown component type:", name);
          break;
        }
      }

      const response = await fetch(`${api.url}/new/${pageName}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      onSelect(); // Close the modal after the request is successful
      document.getElementById("previewer").contentWindow.location.reload();
      fetchData();
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center bg-slate-400 hover:bg-customPink hover:text-white rounded-lg m-2"
      style={{ width: 150, height: 150 }}
      onClick={handleSelect}
    >
      <h1 className="text-center text-white">{name}</h1>
    </div>
  );
}

function NewComponentSelector({ pageName, refresh }) {
  const closeModal = () => {
    document.getElementById("my_modal_4").close();
  };

  return (
    <div>
      <button
        className="btn hover:bg-customPink hover:text-white"
        onClick={() => document.getElementById("my_modal_4").showModal()}
      >
        New Component
      </button>
      <dialog id="my_modal_4" className="modal">
        <div className="modal-box w-11/12 max-w-5xl">
          <div className="flex justify-between items-center">
            <h3 className="font-bold text-lg">Component Selection</h3>
            <button
              className="btn btn-sm btn-circle btn-ghost"
              onClick={closeModal}
            >
              ✕
            </button>
          </div>
          <div className="modal-action">
            <div className="flex flex-wrap">
              <ComponentItem
                pageName={pageName}
                name="Text"
                refresh={refresh}
                onSelect={closeModal}
              />
              <ComponentItem
                pageName={pageName}
                name="Link"
                refresh={refresh}
                onSelect={closeModal}
              />
              <ComponentItem
                pageName={pageName}
                name="Article"
                refresh={refresh}
                onSelect={closeModal}
              />
              <ComponentItem
                pageName={pageName}
                name="Article With Image"
                refresh={refresh}
                onSelect={closeModal}
              />
              <ComponentItem
                pageName={pageName}
                name="Project"
                refresh={refresh}
                onSelect={closeModal}
              />
              <ComponentItem
                pageName={pageName}
                name="Project Wrapper"
                refresh={refresh}
                onSelect={closeModal}
              />
              <ComponentItem
                pageName={pageName}
                name="people"
                refresh={refresh}
                onSelect={closeModal}
              />
              <ComponentItem
                pageName={pageName}
                name="TimeLine"
                refresh={refresh}
                onSelect={closeModal}
              />
            </div>
          </div>
        </div>
      </dialog>
    </div>
  );
}

function PreviewSide({ url }) {
  return (
    <div
      className="flex flex-col items-center bg-slate-600  min-h-screen"
      style={{ width: "40vw" }}
    >
      <h1 className="text-white font-bold text-center mt-2 mb-2">Preview</h1>
      <iframe
        id="previewer"
        src={url}
        className="min-h-full min-w-full"
        title="pae preview"
      ></iframe>
    </div>
  );
}

function EditorSide({ pageName }) {
  const [data, setData] = useState({ body: [] });
  const [refresh, refreshEditor] = useState(true);

  const handleSave = async () => {
    try {
      console.log("data is:", data);
      const response = await fetch(`${api.url}/set/${pageName}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to save data");
      }

      console.log("Data saved successfully");
      document.getElementById("previewer").contentWindow.location.reload();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${api.url}/get/${pageName}`);
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
    // const interval = setInterval(() => {
    //   if (refresh === true) {
    //     refreshEditor(false);
    //     fetchData();
    //   }
    // }, 1000);
    // return () => clearInterval(interval);
  }, [pageName]);

  try {
    let content;
    switch (data.category) {
      case "home":
        content = (
          <>
            <HomeEditorCTranslator
              pageName={pageName}
              data={data}
              setData={setData}
              onSave={handleSave}
            />
          </>
        );
        break;
      default:
        content = (
          <>
            <PageTitleEditor
              data={data}
              setData={setData}
              onSave={handleSave}
            />
            {data["body"].map((element, index) => (
              <EditorCTranslator
                key={index}
                api={api}
                pageName={pageName}
                jsonElement={element}
                data={data}
                refresh={refreshEditor}
                setData={setData}
                onSave={handleSave}
              />
            ))}
          </>
        );
        break;
    }

    try {
      return (
        <div
          className="flex flex-col h-72 overflow-y-auto items-center bg-slate-200 min-h-screen"
          style={{ width: "40vw" }}
        >
          <div className="flex p-2 bg-gray-300 w-full">
            <h1 className="text-amber-900 font-bold text-center m-2">Editor</h1>
            <div className="w-screen"></div>
            <NewComponentSelector
              className="mx-auto"
              pageName={pageName}
              refresh={setData}
            />
          </div>
          {content}
        </div>
      );
    } catch (e) {
      return (
        <div
          className="flex flex-col items-center bg-black min-h-screen"
          style={{ width: "40vw" }}
        >
          <h1 className="text-white font-bold text-center m-2">Editor</h1>
          <h1 className="text-customPink font-bold text-center mt-2">
            Error {e.toString()}
          </h1>
        </div>
      );
    }
  } catch (e) {
    return (
      <div
        className="flex flex-col items-center bg-black min-h-screen"
        style={{ width: "40vw" }}
      >
        <h1 className="text-white font-bold text-center m-2">Editor</h1>
        <h1 className="text-customPink font-bold text-center mt-2">
          Error {e.toString()}
        </h1>
      </div>
    );
  }
}

function AdminPanelEaditor({ page = "/", pname }) {
  return (
    <div className="flex">
      <EditorSide pageName={pname} />
      <PreviewSide url={page} />
    </div>
  );
}

export default AdminPanelEaditor;
