// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDvztl3CCok9s-yuvEqTU8InVkSxVscKZU",
    authDomain: "aksa-kadinlari.firebaseapp.com",
    projectId: "aksa-kadinlari",
    storageBucket: "aksa-kadinlari.appspot.com",
    messagingSenderId: "111476619529",
    appId: "1:111476619529:web:294a8d99ac1ac24cbc3bd8",
    measurementId: "G-DC8W4CN2JB"
  };
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)

export { db, auth, storage }