import React from "react";
import { useTranslation } from "react-i18next";

function CText({
  color = "text-amber-900",
  layout = "",
  weight = "",
  size = "",
  isLink = false,
  link = "/",
  hoverColor = "",
  content = {},
  children,
}) {
  const { t, i18n } = useTranslation();
  const lang = t("lang");
  const data = content[lang];
  return isLink ? (
    // <div className="flex mx-2 ">
    <a
      href={link}
      className={` ${color} ${layout} ${weight} ${size} ${
        hoverColor && `hover:${hoverColor}`
      } `}
      style={{ textDecoration: "none", direction: t("dir") }}
    >
      {data}
      {children}
    </a>
  ) : (
    // </div>
    <h1
      className={`${color} ${weight} ${size} ${
        hoverColor ? `hover:${hoverColor}` : ""
      } ${t("dir") === "rtl" && layout === "" ? "text-start" : ""}`}
    >
      {data}
      <span>{children}</span>
    </h1>
  );
}

export default CText;
