import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import CampaginCard from "../../Components/shared/CampaginCard";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import api from "../../api.json";
import { useSpring, animated } from '@react-spring/web';


function MultipleItems() {
  const [isInView, setIsInView] = useState(false);
  const divRef = useRef(); // Create a ref for the animated div
  const { t, i18n } = useTranslation();

  const springs = useSpring({
    from: { y: 500 },
    to: { y: isInView ? 0 : 500 }, // Only animate when the div is in view
    // delay: 500,
  });

  const [myCollection, setMyCollection] = useState([]); // Initialize the state

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await fetch(`${api.url}/get/campaigns`);
        const data = await response.json();
        console.log("API Response:", data); // Log the response to debug
        if (data && data.body) {
          setMyCollection(data); // Ensure you're accessing the correct property
        } else {
          console.error("Unexpected API response structure", data);
        }
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    };

    fetchCampaigns();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.unobserve(entry.target); // Stop observing once the animation is triggered
          }
        });
      },
      { threshold: 0.1 } // Adjust this value to control when the animation should trigger
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  const getTranslatedText = (textObj) => {
    if (typeof textObj === "object" && textObj !== null) {
      return textObj[i18n.language] || textObj.en; // Fall back to English if the current language is not available
    }
    return textObj;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
  };

  const settings3 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 3,
    arrows: true,
  };

  return (
    <animated.div ref={divRef} className="my-5" style={{ ...springs }}>
      <div className="hidden md:block lg:block">
        <div className="flex flex-col items-center container">
          <h1 className="card-title py-2 text-customPink font-bold text-3xl">
            {myCollection.title ? myCollection.title[t("lang")] : ""}
          </h1>
          <div className="container">
            <Slider {...settings}>
              {myCollection.body
                ? myCollection.body.map((card, index) => (
                    <CampaginCard
                      key={index}
                      title={card.title[t("lang")]}
                      details={card.details[t("lang")]}
                      img={card.img[t("lang")]}
                    />
                  ))
                : null}
            </Slider>
          </div>
        </div>
      </div>

      <div className="block lg:hidden md:hidden sm:block my-5">
        <div className="flex flex-col items-center container">
          <h1 className="card-title my-2 font-bold text-3xl text-customPink">
            {myCollection.title ? myCollection.title[t("lang")] : ""}
          </h1>
          <div className="container">
            <Slider {...settings3}>
              {myCollection.body
                ? myCollection.body.map((card, index) => (
                    <CampaginCard
                      key={index}
                      title={card.title[t("lang")]}
                      details={card.details[t("lang")]}
                      img={card.img[t("lang")]}
                    />
                  ))
                : null}
            </Slider>
          </div>
        </div>
      </div>
    </animated.div>
  );
}

export default MultipleItems;

// [
//   {
//     title: "First card",
//     details:
//       "hello tessdknkbnklnlknl nssnbdvkjdnbkjdfnkj nkjn fn;skjdgnkkj  non on",
//     img: "https://ihyamiras.org/en/upload/arsiv/slayt-Facebook-New-08-K1DJHMAEPKHUC8X9U5BC.jpg",
//   },
//   {
//     title: "First card",
//     details:
//       "hello tessdknkbnklnlknl nssnbdvkjdnbkjdfnkj nkjn fn;skjdgnkkj  non on",
//     img: "https://ihyamiras.org/en/upload/arsiv/slayt-Facebook-New-08-K1DJHMAEPKHUC8X9U5BC.jpg",
//   },
//   {
//     title: "First card",
//     details:
//       "hello tessdknkbnklnlknl nssnbdvkjdnbkjdfnkj nkjn fn;skjdgnkkj  non on",
//     img: "https://ihyamiras.org/en/upload/arsiv/slayt-Facebook-New-08-K1DJHMAEPKHUC8X9U5BC.jpg",
//   },
//   {
//     title: "First card",
//     details:
//       "hello tessdknkbnklnlknl nssnbdvkjdnbkjdfnkj nkjn fn;skjdgnkkj  non on",
//     img: "https://ihyamiras.org/en/upload/arsiv/slayt-Facebook-New-08-K1DJHMAEPKHUC8X9U5BC.jpg",
//   },
//   {
//     title: "First card",
//     details:
//       "hello tessdknkbnklnlknl nssnbdvkjdnbkjdfnkj nkjn fn;skjdgnkkj  non on",
//     img: "https://ihyamiras.org/en/upload/arsiv/slayt-Facebook-New-08-K1DJHMAEPKHUC8X9U5BC.jpg",
//   },
//   {
//     title: "First card",
//     details:
//       "hello tessdknkbnklnlknl nssnbdvkjdnbkjdfnkj nkjn fn;skjdgnkkj  non on",
//     img: "https://ihyamiras.org/en/upload/arsiv/slayt-Facebook-New-08-K1DJHMAEPKHUC8X9U5BC.jpg",
//   },
// ];
