import React, { useEffect, useState } from "react";
import FileUploader from "../../Components/FileUploader/fileUploader";

function EditorCTranslator({
  api,
  pageName,
  jsonElement,
  data,
  refresh,
  setData,
  onSave,
}) {
  var ids = [];
  console.log("rootid is: ", jsonElement.id);
  const [elementid, setElementID] = useState("");
  // Recursive function to get ids and types
  function getIdsAndTypes(elements) {
    elements.forEach((item) => {
      ids.push({ id: item.id, type: item.type });

      // If the element has children, call the function recursively
      if (item.children && item.children.length > 0) {
        getIdsAndTypes(item.children);
      }
    });
  }

  // Start from the top-level body elements
  if (data && data.body) {
    getIdsAndTypes(data.body);
  }

  // Filter out duplicates
  const uniqueIds = ids.filter(
    (value, index, self) => index === self.findIndex((t) => t.id === value.id)
  );

  const handleContentChange = async (language, value, elementId) => {
    try {
      // Update the data state
      setData((prevData) => {
        const updateContent = (elements) =>
          elements.map((item) => {
            if (item.id === elementId) {
              return {
                ...item,
                content: { ...item.content, [language]: value },
              };
            } else if (item.children && item.children.length > 0) {
              return {
                ...item,
                children: updateContent(item.children),
              };
            }
            return item;
          });

        return {
          ...prevData,
          body: updateContent(prevData.body),
        };
      });

      // Find the updated element
      const updatedElement = data.body.find((item) => item.id === elementId);

      // Handle successful response (e.g., close modal, reload preview)
      document.getElementById("previewer").contentWindow.location.reload();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const onDelete = async () => {
    try {
      const response = await fetch(
        `${api.url}/remove/${pageName}/${jsonElement.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete data");
      }

      console.log("Data deleted successfully");

      // Update the state to remove the deleted element
      setData((prevData) => {
        const removeElement = (elements) => {
          return elements
            .filter((item) => item.id !== jsonElement.id) // Remove the deleted item
            .map((item) => {
              if (item.children && item.children.length > 0) {
                return {
                  ...item,
                  children: removeElement(item.children),
                };
              }
              return item;
            });
        };

        return {
          ...prevData,
          body: removeElement(prevData.body),
        };
      });

      // Optionally, reload the previewer if needed
      document.getElementById("previewer").contentWindow.location.reload();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  // Recursive function to find an element by ID
  function findElementById(elements, id) {
    for (const item of elements) {
      if (item.id === id) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        const found = findElementById(item.children, id);
        if (found) return found;
      }
    }
    return null;
  }

  const handleSelectChange2 = (event) => {
    const selectedId = event.target.value;
    setElementID(selectedId);
    const selectedElement = findElementById(data.body, selectedId);
    console.log("Selected element:", selectedElement);
  };

  function ComponentItem({ name, onSelect }) {
    const fetchData = async () => {
      try {
        const response = await fetch(`${api.url}/get/${pageName}`);
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const handleSelect = async () => {
      const updateElement = async (updatedElement) => {
        try {
          console.log("hello world : ", elementid);
          // Make the PUT request
          const response = await fetch(
            `${api.url}/update/${pageName}/${elementid}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(updatedElement),
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          fetchData();
          // Handle successful response (e.g., close modal, reload preview)
          onSelect(); // Close the modal after the request is successful
          document.getElementById("previewer").contentWindow.location.reload();
        } catch (error) {
          console.error("Error updating data:", error);
        }
      };

      function generateRandomId() {
        const chars = "0123456789abcdef";
        let id = "";
        for (let i = 0; i < 24; i++) {
          id += chars[Math.floor(Math.random() * chars.length)];
        }
        return id;
      }

      try {
        var jsonBody = {};
        switch (name) {
          case "Text": {
            jsonBody = {
              id: generateRandomId(),
              type: "text",
              color: "text-amber-900",
              hoverColor: "",
              layout: "",
              size: "",
              content: {
                ar: "نص عينة",
                en: "sample Text ",
                tr: "Sample Text in Turkish",
              },
              weight: "font-lg",
              isLink: false,
              link: "",
              children: [],
            };
            break;
          }
          case "Link": {
            jsonBody = {
              id: generateRandomId(),
              type: "text",
              color: "text-amber-900",
              hoverColor: "",
              layout: "",
              size: "",
              content: {
                ar: "نص رابط",
                en: "sample Text ",
                tr: "Sample Text in Turkish",
              },
              weight: "font-lg",
              isLink: true,
              link: "/",
              children: [],
            };
            break;
          }
          case "Article": {
            jsonBody = {
              id: generateRandomId(),
              type: "simple-article",
              background: "/images/kampanya_bg.png",
              layout: "",
              spacing: "space-y-2",
              children: [
                {
                  id: generateRandomId(),
                  type: "text",
                  color: "text-customPink",
                  hoverColor: "",
                  layout: "",
                  size: "text-2xl",
                  content: {
                    ar: " عنوان",
                    en: "Title ",
                    tr: "Sample Title in Turkish",
                  },
                  weight: "font-lg",
                  isLink: false,
                  link: "",
                  children: [],
                },
                {
                  id: generateRandomId(),
                  type: "text",
                  color: "text-customOrange",
                  hoverColor: "",
                  layout: "",
                  size: "",
                  content: {
                    ar: "في عالمٍ يزداد تعقيدًا، أصبحت التكنولوجيا جزءًا لا يتجزأ من حياتنا اليومية. لقد غيرت الطريقة التي نعيش بها، ونعمل، ونتواصل مع الآخرين. سواءً كان ذلك من خلال الهواتف الذكية التي نحملها في جيوبنا، أو الأنظمة الذكية التي تدير منازلنا، فإن التكنولوجيا تساهم بشكل كبير في تحسين حياتنا وتسهيل مهامنا اليومية. ولكن مع هذه الفوائد، تأتي تحديات جديدة تتطلب منا أن نكون أكثر وعيًا وإدراكًا لتأثيرات التكنولوجيا على حياتنا.",
                    en: "In an increasingly complex world, technology has become an integral part of our daily lives. It has changed the way we live, work, and communicate with others. Whether through the smartphones we carry in our pockets or the smart systems that manage our homes, technology plays a significant role in improving our lives and making our daily tasks easier. But with these benefits come new challenges that require us to be more aware and conscious of technology's impact on our lives. ",
                    tr: "Giderek karmaşıklaşan bir dünyada, teknoloji günlük hayatımızın ayrılmaz bir parçası haline geldi. Yaşam şeklimizi, çalışma tarzımızı ve başkalarıyla iletişim kurma yöntemlerimizi değiştirdi. Cebimizde taşıdığımız akıllı telefonlar ya da evlerimizi yöneten akıllı sistemler aracılığıyla, teknoloji hayatımızı iyileştirmede ve günlük görevlerimizi kolaylaştırmada önemli bir rol oynuyor. Ancak bu faydalarla birlikte, teknolojinin hayatımız üzerindeki etkisinin daha fazla farkında olmamızı gerektiren yeni zorluklar da ortaya çıkıyor.",
                  },
                  weight: "font-lg",
                  isLink: false,
                  link: "",
                  children: [],
                },
              ],
            };
            break;
          }

          case "Article With Image": {
            jsonBody = {
              id: generateRandomId(),
              type: "article-with-img",
              background: "",
              img: {
                en: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
                ar: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
                tr: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
              },
              children: [
                {
                  id: generateRandomId(),
                  type: "text",
                  color: "text-customPink",
                  hoverColor: "",
                  layout: "",
                  size: "text-2xl",
                  content: {
                    ar: " عنوان",
                    en: "Title ",
                    tr: "Sample Title in Turkish",
                  },
                  weight: "font-lg",
                  isLink: false,
                  link: "",
                  children: [],
                },
                {
                  id: generateRandomId(),
                  type: "text",
                  color: "text-customOrange",
                  hoverColor: "",
                  layout: "",
                  size: "",
                  content: {
                    ar: "في عالمٍ يزداد تعقيدًا، أصبحت التكنولوجيا جزءًا لا يتجزأ من حياتنا اليومية. لقد غيرت الطريقة التي نعيش بها، ونعمل، ونتواصل مع الآخرين. سواءً كان ذلك من خلال الهواتف الذكية التي نحملها في جيوبنا، أو الأنظمة الذكية التي تدير منازلنا، فإن التكنولوجيا تساهم بشكل كبير في تحسين حياتنا وتسهيل مهامنا اليومية. ولكن مع هذه الفوائد، تأتي تحديات جديدة تتطلب منا أن نكون أكثر وعيًا وإدراكًا لتأثيرات التكنولوجيا على حياتنا.",
                    en: "In an increasingly complex world, technology has become an integral part of our daily lives. It has changed the way we live, work, and communicate with others. Whether through the smartphones we carry in our pockets or the smart systems that manage our homes, technology plays a significant role in improving our lives and making our daily tasks easier. But with these benefits come new challenges that require us to be more aware and conscious of technology's impact on our lives. ",
                    tr: "Giderek karmaşıklaşan bir dünyada, teknoloji günlük hayatımızın ayrılmaz bir parçası haline geldi. Yaşam şeklimizi, çalışma tarzımızı ve başkalarıyla iletişim kurma yöntemlerimizi değiştirdi. Cebimizde taşıdığımız akıllı telefonlar ya da evlerimizi yöneten akıllı sistemler aracılığıyla, teknoloji hayatımızı iyileştirmede ve günlük görevlerimizi kolaylaştırmada önemli bir rol oynuyor. Ancak bu faydalarla birlikte, teknolojinin hayatımız üzerindeki etkisinin daha fazla farkında olmamızı gerektiren yeni zorluklar da ortaya çıkıyor.",
                  },
                  weight: "font-lg",
                  isLink: false,
                  link: "",
                  children: [],
                },
              ],
            };
            break;
          }

          case "Project Wrapper": {
            jsonBody = {
              id: generateRandomId(),
              type: "card-wrapper",
              title: {
                ar: "اسم المشروع",
                en: "ProjectTitle",
                tr: "ProjectTitle in turkish",
              },
              children: [
                {
                  id: generateRandomId(),
                  img: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
                  type: "project-card",
                  title: {
                    ar: "اسم المشروع",
                    en: "ProjectTitle",
                    tr: "ProjectTitle in turkish",
                  },
                  hoverColor: "bg-customOrange",
                  overlayColor: "bg-black",
                  route: "/",
                },
              ],
            };
            break;
          }

          case "Project": {
            jsonBody = {
              id: generateRandomId(),
              img: {
                en: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
                ar: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
                tr: "https://letsenhance.io/static/73136da51c245e80edc6ccfe44888a99/1015f/MainBefore.jpg",
              },
              type: "project-card",
              title: {
                ar: "اسم المشروع",
                en: "ProjectTitle",
                tr: "ProjectTitle in turkish",
              },
              hoverColor: "bg-customOrange",
              overlayColor: "bg-black",
              route: "/",
            };
            break;
          }

          case "people": {
            jsonBody = {
              id: generateRandomId(),
              type: "people",
              people: [
                {
                  img: "/images/people.webp",
                  name: "amr basem in tr",
                  country: "Agypten",
                  details: "hello proj2",
                },
              ],
            };
            break;
          }

          default: {
            console.error("Unknown component type:", name);
            break;
          }
        }

        console.log("element iddd: ", jsonElement.id);

        // jsonElement.children.push(jsonBody);
        const selectedElement = findElementById(data.body, elementid);
        selectedElement.children.push(jsonBody);
        updateElement(selectedElement);

        onSelect(); // Close the modal after the request is successful
        document.getElementById("previewer").contentWindow.location.reload();
      } catch (error) {
        console.error("Error making PUT request:", error);
      }
    };

    return (
      <div
        className="flex flex-col items-center justify-center bg-slate-400 hover:bg-customPink hover:text-white rounded-lg m-2"
        style={{ width: 150, height: 150 }}
        onClick={handleSelect}
      >
        <h1 className="text-center text-white">{name}</h1>
      </div>
    );
  }

  function generateRandomText(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  function NewComponentSelector({ avilableComponent }) {
    const dialogName = generateRandomText(20);
    const closeModal = () => {
      document.getElementById(dialogName).close();
    };

    return (
      <div>
        <button
          className="btn hover:bg-customPink hover:text-white"
          onClick={() => document.getElementById(dialogName).showModal()}
        >
          New Component
        </button>
        <dialog id={dialogName} className="modal">
          <div className="modal-box w-11/12 max-w-5xl">
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-lg">Component Selection</h3>
              <button
                className="btn btn-sm btn-circle btn-ghost"
                onClick={closeModal}
              >
                ✕
              </button>
            </div>
            <select
              className="select w-full max-w-xs"
              onChange={handleSelectChange2}
            >
              <option disabled selected>
                Pick your parent element
              </option>
              {uniqueIds.map((i) => (
                <option key={i.id} value={i.id}>
                  {i.id}, {i.type}
                </option>
              ))}
            </select>
            <div className="modal-action">
              <div className="flex flex-wrap">
                {avilableComponent.map((item, index) => (
                  <ComponentItem
                    key={index} // Use index as key if no unique id is available
                    name={item}
                    onSelect={closeModal}
                  />
                ))}
              </div>
            </div>
          </div>
        </dialog>
      </div>
    );
  }

  switch (jsonElement.type) {
    case "text": {
      const handleSelectChange = (field, value) => {
        // Recursive function to search and update the item by id
        const updateItemById = (items) => {
          return items.map((item) => {
            if (item.id === jsonElement.id) {
              // Modify the specific field of the found item
              return { ...item, [field]: value };
            } else if (item.children) {
              // If the item has children, search recursively within them
              return { ...item, children: updateItemById(item.children) };
            }
            return item;
          });
        };

        // Update the data.body with the modified structure
        const updatedBody = updateItemById(data.body);

        // Set the updated data with the new body
        setData((prevData) => ({
          ...prevData,
          body: updatedBody,
        }));
      };

      return (
        <div className="flex flex-col bg-slate-200 shadow-lg m-2 min-w-full space-y-3 p-5">
          <h1>{jsonElement.id}</h1>

          <h1>Arabic</h1>
          <textarea
            value={jsonElement.content.ar}
            onChange={(e) =>
              handleContentChange("ar", e.target.value, jsonElement.id)
            }
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          <h1>English</h1>
          <textarea
            value={jsonElement.content.en}
            onChange={(e) =>
              handleContentChange("en", e.target.value, jsonElement.id)
            }
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          <h1>Turkish</h1>
          <textarea
            value={jsonElement.content.tr}
            onChange={(e) =>
              handleContentChange("tr", e.target.value, jsonElement.id)
            }
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          {/* hoverColor */}
          <select
            className="select w-full max-w-xs"
            value={jsonElement.hoverColor.replace("text-custom", "")}
            onChange={(e) =>
              handleSelectChange(
                "hoverColor",
                e.target.value === "amber-900"
                  ? "text-amber-900"
                  : `text-custom${e.target.value}`
              )
            }
          >
            <option disabled={!jsonElement.hoverColor}>Pick Hover Color</option>
            <option value="Pink">pink</option>
            <option value="Orange">orange</option>
            <option value="amber-900">brown</option>
          </select>

          {/* color */}
          <select
            className="select w-full max-w-xs"
            value={jsonElement.color}
            onChange={(e) =>
              handleSelectChange(
                "color",
                e.target.value === "amber-900"
                  ? "text-amber-900"
                  : `text-custom${e.target.value}`
              )
            }
          >
            <option disabled={!jsonElement.color}>Pick Color</option>
            <option value="Pink">pink</option>
            <option value="Orange">orange</option>
            <option value="amber-900">brown</option>
          </select>

          {/* Layout */}
          <select
            className="select w-full max-w-xs"
            value={jsonElement.layout}
            onChange={(e) => handleSelectChange("layout", e.target.value)}
          >
            <option disabled={!jsonElement.color}>Pick LayOut</option>
            <option value="text-start">Start</option>
            <option value="text-center">Center</option>
            <option value="text-end">End</option>
            <option value="">Default By Language</option>
          </select>

          {/* size */}
          <select
            className="select w-full max-w-xs"
            value={jsonElement.size}
            onChange={(e) => handleSelectChange("size", e.target.value)}
          >
            <option disabled={!jsonElement.size}>Pick Size</option>
            <option value="text-xs">very small</option>
            <option value="text-sm">small</option>
            <option value="text-base">normal</option>
            <option value="text-lg">large</option>
            <option value="text-xl">x large</option>
            <option value="text-2xl">2x large</option>
            <option value="text-3xl">3x large</option>
          </select>

          {/* weight */}
          <select
            className="select w-full max-w-xs"
            value={jsonElement.weight}
            onChange={(e) => handleSelectChange("weight", e.target.value)}
          >
            <option disabled={!jsonElement.weight}>Pick Weight</option>
            <option value="font-thin">very small</option>
            <option value="font-extralight">small</option>
            <option value="font-light">normal</option>
            <option value="font-normal">large</option>
            <option value="font-medium">x large</option>
            <option value="font-semibold">2x large</option>
            <option value="font-bold">3x large</option>
            <option value="font-black">heaviest</option>
          </select>

          <h1>Set Link</h1>
          <input
            type="checkbox"
            checked={jsonElement.isLink} // Bind the checkbox to jsonElement.isLink
            onChange={(e) => handleSelectChange("isLink", e.target.checked)} // Update the value based on the checkbox state
            className="toggle"
          />

          {jsonElement.isLink === true ? (
            <>
              <h1>Route Link</h1>
              <textarea
                value={jsonElement.link}
                onChange={(e) =>
                  handleSelectChange("link", e.target.value, jsonElement.id)
                }
                className="textarea textarea-bordered textarea-md w-full"
              ></textarea>
            </>
          ) : (
            <div />
          )}

          {/* Recursive children mapping */}
          {jsonElement.children &&
            jsonElement.children.map((child, index) => (
              <EditorCTranslator
                key={index}
                api={api}
                pageName={pageName}
                setData={setData}
                data={data}
                jsonElement={child}
                onSave={onSave}
              />
            ))}

          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onSave}
          >
            Save
          </button>
          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onDelete}
          >
            Delete
          </button>

          <NewComponentSelector avilableComponent={["Text", "Link"]} />
        </div>
      );
    }

    case "simple-article": {
      const handleSelectChange = (field, value) => {
        setData((prevData) => ({
          ...prevData,
          body: prevData.body.map((item) =>
            item.id === jsonElement.id
              ? {
                  ...item,
                  [field]: value,
                }
              : item
          ),
        }));
      };

      return (
        <div className="flex flex-col bg-slate-400 shadow-lg m-2 min-w-full space-y-3 p-5">
          {/* id */}
          <h1 className="flex text-center self-center"> {jsonElement.id}</h1>

          {/* background */}
          <select
            className="select w-full max-w-xs"
            value={jsonElement.background}
            onChange={(e) => handleSelectChange("background", e.target.value)}
          >
            <option disabled selected={!jsonElement.background}>
              Pick article background
            </option>
            <option>/images/istatistik-zemin.png</option>
            <option>/images/kampanya_bg.png</option>
            <option>/images/detay-banner.jpg</option>
          </select>

          {/* x spacing */}
          <select
            className="select w-full max-w-xs"
            value={
              jsonElement.spacing
                ? jsonElement.spacing.split(" ")[0].replace("space-x-", "")
                : ""
            }
            onChange={(e) =>
              handleSelectChange(
                "spacing",
                `space-x-${e.target.value} space-y-${jsonElement.spacing
                  ?.split(" ")[1]
                  .replace("space-y-", "")}`
              )
            }
          >
            <option disabled selected={!jsonElement.spacing}>
              Pick x spacing
            </option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>

          {/* y spacing */}
          <select
            className="select w-full max-w-xs"
            value={
              jsonElement.spacing?.split(" ")[1]?.replace("space-y-", "") || ""
            }
            onChange={(e) =>
              handleSelectChange(
                "spacing",
                `space-x-${
                  jsonElement.spacing?.split(" ")[0]?.replace("space-x-", "") ||
                  ""
                } space-y-${e.target.value}`
              )
            }
          >
            <option disabled selected={!jsonElement.spacing}>
              Pick y spacing
            </option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>

          {/* layout */}
          <select
            className="select w-full max-w-xs"
            value={jsonElement.layout}
            onChange={(e) => handleSelectChange("layout", e.target.value)}
          >
            <option disabled selected={!jsonElement.layout}>
              Pick layout
            </option>
            <option value="items-start">start</option>
            <option value="items-center">center</option>
            <option value="items-end">end</option>
            <option value="">default by language</option>
          </select>

          {/* recursive children mapping */}
          {jsonElement.children.map((child, index) => (
            <EditorCTranslator
              key={index}
              api={api}
              pageName={pageName}
              setData={setData}
              data={data}
              jsonElement={child}
              onSave={onSave}
            />
          ))}

          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onSave}
          >
            Save
          </button>

          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onDelete}
          >
            Delete
          </button>

          <NewComponentSelector avilableComponent={["Text", "Link"]} />
        </div>
      );
    }

    case "article-with-img": {
      const handleSelectChange = (field, value) => {
        setData((prevData) => ({
          ...prevData,
          body: prevData.body.map((item) =>
            item.id === jsonElement.id
              ? {
                  ...item,
                  [field]: value,
                }
              : item
          ),
        }));
      };
      const handleSelectChange2 = (field, subField, value) => {
        setData((prevData) => ({
          ...prevData,
          body: prevData.body.map((item) =>
            item.id === jsonElement.id
              ? {
                  ...item,
                  [field]: {
                    ...item[field],
                    [subField]: value,
                  },
                }
              : item
          ),
        }));
      };

      return (
        <div className="flex flex-col bg-slate-400 shadow-lg m-2 min-w-full space-y-3 p-5">
          {/* id */}
          <h1 className="flex text-center self-center"> {jsonElement.id}</h1>

          {/* background */}
          <select
            className="select w-full max-w-xs"
            value={jsonElement.background}
            onChange={(e) => handleSelectChange("background", e.target.value)}
          >
            <option disabled selected={!jsonElement.background}>
              Pick article background
            </option>
            <option>/images/istatistik-zemin.png</option>
            <option>/images/kampanya_bg.png</option>
            <option>/images/detay-banner.jpg</option>
          </select>

          {/* img */}
          <h1>img for Arabic</h1>
          <textarea
            value={jsonElement.img.ar}
            onChange={(e) => handleSelectChange2("img", "ar", e.target.value)}
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          <FileUploader
            onFileLoaded={(e) => handleSelectChange2("img", "ar", e)}
          />

          {/* img */}
          <h1>img for English</h1>
          <textarea
            value={jsonElement.img.en}
            onChange={(e) => handleSelectChange2("img", "en", e.target.value)}
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          <FileUploader
            onFileLoaded={(e) => handleSelectChange2("img", "en", e)}
          />

          {/* img */}
          <h1>img for Turkish</h1>
          <textarea
            value={jsonElement.img.tr}
            onChange={(e) => handleSelectChange2("img", "tr", e.target.value)}
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          <FileUploader
            onFileLoaded={(e) => handleSelectChange2("img", "tr", e)}
          />

          {/* recursive children mapping */}
          {jsonElement.children.map((child, index) => (
            <EditorCTranslator
              key={index}
              api={api}
              pageName={pageName}
              setData={setData}
              data={data}
              jsonElement={child}
              onSave={onSave}
            />
          ))}

          <NewComponentSelector avilableComponent={["Text", "Link"]} />

          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onSave}
          >
            Save
          </button>

          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onDelete}
          >
            Delete
          </button>
        </div>
      );
    }

    case "card-wrapper": {
      const handleSelectChange = (field, value) => {
        setData((prevData) => ({
          ...prevData,
          body: prevData.body.map((item) =>
            item.id === jsonElement.id
              ? {
                  ...item,
                  [field]: value,
                }
              : item
          ),
        }));
      };

      return (
        <div className="flex flex-col bg-slate-400 shadow-lg m-2 min-w-full space-y-3 p-5">
          {/* id */}
          <h1 className="flex text-center self-center"> {jsonElement.id}</h1>

          {/* title */}
          <h1>title</h1>
          <textarea
            value={jsonElement.title}
            onChange={(e) => handleSelectChange("title", e.target.value)}
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          {/* recursive children mapping */}
          {jsonElement.children.map((child, index) => (
            <EditorCTranslator
              key={index}
              api={api}
              pageName={pageName}
              setData={setData}
              data={data}
              jsonElement={child}
              onSave={onSave}
            />
          ))}

          <NewComponentSelector
            avilableComponent={["Text", "Link", "Project"]}
          />

          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onSave}
          >
            Save
          </button>

          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onDelete}
          >
            Delete
          </button>
        </div>
      );
    }

    case "project-card": {
      const handleSelectChange = (field, value) => {
        // Recursive function to search and update the item by id
        const updateItemById = (items) => {
          return items.map((item) => {
            if (item.id === jsonElement.id) {
              // Modify the specific field of the found item
              return { ...item, [field]: value };
            } else if (item.children) {
              // If the item has children, search recursively within them
              return { ...item, children: updateItemById(item.children) };
            }
            return item;
          });
        };

        // Update the data.body with the modified structure
        const updatedBody = updateItemById(data.body);

        // Set the updated data with the new body
        setData((prevData) => ({
          ...prevData,
          body: updatedBody,
        }));
      };

      const handleSelectChang2 = (field, subField, value) => {
        // Recursive function to search and update the item by id
        const updateItemById = (items) => {
          return items.map((item) => {
            if (item.id === jsonElement.id) {
              // Modify the specific subField of the found item
              return {
                ...item,
                [field]: {
                  ...item[field],
                  [subField]: value,
                },
              };
            } else if (item.children) {
              // If the item has children, search recursively within them
              return {
                ...item,
                children: updateItemById(item.children),
              };
            }
            return item;
          });
        };

        setData((prevData) => ({
          ...prevData,
          body: updateItemById(prevData.body),
        }));
      };

      return (
        <div className="flex flex-col bg-slate-400 shadow-lg m-2 min-w-full space-y-3 p-5">
          {/* id */}
          <h1 className="flex text-center self-center"> {jsonElement.id}</h1>

          {/* title */}
          <h1>title in english</h1>
          <textarea
            value={jsonElement.title?.en || ""} // Fallback to empty string if undefined
            onChange={(e) => handleSelectChang2("title", "en", e.target.value)}
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          <h1>title in arabic</h1>
          <textarea
            value={jsonElement.title?.ar || ""} // Fallback to empty string if undefined
            onChange={(e) => handleSelectChang2("title", "ar", e.target.value)}
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          <h1>title in turkish</h1>
          <textarea
            value={jsonElement.title?.tr || ""} // Fallback to empty string if undefined
            onChange={(e) => handleSelectChang2("title", "tr", e.target.value)}
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          {/* img */}
          <h1>img for Arabic</h1>
          <textarea
            value={jsonElement.img.ar}
            onChange={(e) => handleSelectChang2("img", "ar", e.target.value)}
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          <FileUploader
            onFileLoaded={(e) => handleSelectChang2("img", "ar", e)}
          />

          {/* img */}
          <h1>img for English</h1>
          <textarea
            value={jsonElement.img.en}
            onChange={(e) => handleSelectChang2("img", "en", e.target.value)}
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          <FileUploader
            onFileLoaded={(e) => handleSelectChang2("img", "en", e)}
          />

          {/* img */}
          <h1>img for Turkish</h1>
          <textarea
            value={jsonElement.img.tr}
            onChange={(e) => handleSelectChang2("img", "tr", e.target.value)}
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          <FileUploader
            onFileLoaded={(e) => handleSelectChang2("img", "tr", e)}
          />

          {/* route */}
          <h1>route</h1>
          <textarea
            value={jsonElement.route}
            onChange={(e) => handleSelectChange("route", e.target.value)}
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          {/* hoverColor */}
          <select
            className="select w-full max-w-xs"
            value={jsonElement.hoverColor.replace("bg-custom", "")}
            onChange={(e) =>
              handleSelectChange("hoverColor", `bg-custom${e.target.value}`)
            }
          >
            <option disabled selected={!jsonElement.hoverColor}>
              Pick Hover Color
            </option>
            <option value="Pink">pink</option>
            <option value="Orange">orange</option>
          </select>

          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onSave}
          >
            Save
          </button>

          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onDelete}
          >
            Delete
          </button>
        </div>
      );
    }

    case "people": {
      const handleContentChange = (field, value, personIndex) => {
        setData((prevData) => ({
          ...prevData,
          body: prevData.body.map((item) =>
            item.id === jsonElement.id
              ? {
                  ...item,
                  people: item.people.map((person, index) =>
                    index === personIndex
                      ? {
                          ...person,
                          [field]: value,
                        }
                      : person
                  ),
                }
              : item
          ),
        }));
      };

      function onPeopleAdd() {
        // Create a new person entry
        const newPerson = {
          img: "/images/user.png",
          name: "اسم الشخص",
          country: "مقر الشخص",
          details: "المنصب الوظيفي",
        };

        // Update jsonElement.people immutably
        const updatedPeople = [...jsonElement.people, newPerson];

        // Update the state
        setData((prevData) => ({
          ...prevData,
          body: prevData.body.map((item) =>
            item.id === jsonElement.id
              ? {
                  ...item,
                  people: updatedPeople,
                }
              : item
          ),
        }));
      }
      const handleDeletePerson = (personIndex) => {
        setData((prevData) => ({
          ...prevData,
          body: prevData.body.map((item) =>
            item.id === jsonElement.id
              ? {
                  ...item,
                  people: item.people.filter(
                    (_, index) => index !== personIndex
                  ),
                }
              : item
          ),
        }));
      };

      return (
        <div className="flex flex-col bg-slate-400 shadow-lg m-2 min-w-full space-y-3 p-5">
          {/* id */}
          <h1 className="flex text-center self-center">{jsonElement.id}</h1>

          {jsonElement.people.map((person, index) => (
            <div key={index} className="bg-white p-4 rounded-md shadow-lg">
              <h1>Image</h1>
              <textarea
                value={person.img}
                onChange={(e) =>
                  handleContentChange("img", e.target.value, index)
                }
                className="textarea textarea-bordered textarea-md w-full"
              ></textarea>
              <FileUploader
                onFileLoaded={(e) => handleContentChange("img", e, index)}
              />

              <h1>Name</h1>
              <textarea
                value={person.name}
                onChange={(e) =>
                  handleContentChange("name", e.target.value, index)
                }
                className="textarea textarea-bordered textarea-md w-full"
              ></textarea>

              <h1>Country</h1>
              <textarea
                value={person.country}
                onChange={(e) =>
                  handleContentChange("country", e.target.value, index)
                }
                className="textarea textarea-bordered textarea-md w-full"
              ></textarea>

              <h1>Details</h1>
              <textarea
                value={person.details}
                onChange={(e) =>
                  handleContentChange("details", e.target.value, index)
                }
                className="textarea textarea-bordered textarea-md w-full"
              ></textarea>

              {/* Delete Button */}
              <button
                className="btn btn-error mt-2 hover:bg-red-600 text-white"
                onClick={() => handleDeletePerson(index)}
              >
                Delete Person
              </button>
            </div>
          ))}

          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={() => onPeopleAdd()}
          >
            Add Person
          </button>

          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onSave}
          >
            Save
          </button>

          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onDelete}
          >
            Delete
          </button>
        </div>
      );
    }

    case "timeline": {
      const handleContentChange = (field, value, targetIndex) => {
        setData((prevData) => ({
          ...prevData,
          body: prevData.body.map((item) =>
            item.id === jsonElement.id
              ? {
                  ...item,
                  data: item.data.map((target, index) =>
                    index === targetIndex
                      ? {
                          ...target,
                          [field]: value,
                        }
                      : target
                  ),
                }
              : item
          ),
        }));
      };

      const onAddTarget = () => {
        // Create a new target entry
        const newTarget = {
          ar: "نص عربي جديد",
          en: "New English Text",
          tr: "Yeni Türkçe Metin",
        };

        // Update jsonElement.data immutably
        const updatedData = [...jsonElement.data, newTarget];

        // Update the state
        setData((prevData) => ({
          ...prevData,
          body: prevData.body.map((item) =>
            item.id === jsonElement.id
              ? {
                  ...item,
                  data: updatedData,
                }
              : item
          ),
        }));
      };

      const handleDeleteTarget = (targetIndex) => {
        setData((prevData) => ({
          ...prevData,
          body: prevData.body.map((item) =>
            item.id === jsonElement.id
              ? {
                  ...item,
                  data: item.data.filter((_, index) => index !== targetIndex),
                }
              : item
          ),
        }));
      };

      return (
        <div className="flex flex-col bg-slate-400 shadow-lg m-2 min-w-full space-y-3 p-5">
          {/* id */}
          <h1 className="flex text-center self-center">{jsonElement.id}</h1>

          {/* Title in different languages */}
          <h1>Title</h1>
          <h1>Arabic</h1>
          <textarea
            value={jsonElement.title.ar}
            onChange={(e) =>
              setData((prevData) => ({
                ...prevData,
                body: prevData.body.map((item) =>
                  item.id === jsonElement.id
                    ? {
                        ...item,
                        title: { ...item.title, ar: e.target.value },
                      }
                    : item
                ),
              }))
            }
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          <h1>English</h1>
          <textarea
            value={jsonElement.title.en}
            onChange={(e) =>
              setData((prevData) => ({
                ...prevData,
                body: prevData.body.map((item) =>
                  item.id === jsonElement.id
                    ? {
                        ...item,
                        title: { ...item.title, en: e.target.value },
                      }
                    : item
                ),
              }))
            }
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          <h1>Turkish</h1>
          <textarea
            value={jsonElement.title.tr}
            onChange={(e) =>
              setData((prevData) => ({
                ...prevData,
                body: prevData.body.map((item) =>
                  item.id === jsonElement.id
                    ? {
                        ...item,
                        title: { ...item.title, tr: e.target.value },
                      }
                    : item
                ),
              }))
            }
            className="textarea textarea-bordered textarea-md w-full"
          ></textarea>

          {/* Data array */}
          {jsonElement.data.map((target, index) => (
            <div key={index} className="bg-white p-4 rounded-md shadow-lg">
              <h1>Arabic</h1>
              <textarea
                value={target.ar}
                onChange={(e) =>
                  handleContentChange("ar", e.target.value, index)
                }
                className="textarea textarea-bordered textarea-md w-full"
              ></textarea>

              <h1>English</h1>
              <textarea
                value={target.en}
                onChange={(e) =>
                  handleContentChange("en", e.target.value, index)
                }
                className="textarea textarea-bordered textarea-md w-full"
              ></textarea>

              <h1>Turkish</h1>
              <textarea
                value={target.tr}
                onChange={(e) =>
                  handleContentChange("tr", e.target.value, index)
                }
                className="textarea textarea-bordered textarea-md w-full"
              ></textarea>

              {/* Delete Button */}
              <button
                className="btn btn-error mt-2 hover:bg-red-600 text-white"
                onClick={() => handleDeleteTarget(index)}
              >
                Delete Target
              </button>
            </div>
          ))}

          {/* Add Target Button */}
          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onAddTarget}
          >
            Add Target
          </button>

          {/* Save Button */}
          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onSave}
          >
            Save
          </button>

          {/* Delete Timeline Button */}
          <button
            className="btn btn-wide self-center hover:bg-customPink hover:text-white"
            onClick={onDelete}
          >
            Delete
          </button>
        </div>
      );
    }

    default:
      return null;
  }
}

export default EditorCTranslator;
