import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import api from "../../api.json";
import { useTranslation } from "react-i18next";

function FirstCarsoul() {
  // State to hold the fetched data
  const [myCollection, setMyCollection] = useState([]);

  // Fetch data on component mount
  useEffect(() => {
    const fetchCarouselData = async () => {
      try {
        const response = await fetch(`${api.url}/get/cursoul`);
        const data = await response.json();
        setMyCollection(data.body); // Assuming the data is inside the 'body' key
      } catch (error) {
        console.error("Error fetching carousel data:", error);
      }
    };

    fetchCarouselData();
  }, []); // Empty dependency array ensures this runs only once on mount
  const { t, i18n } = useTranslation();
  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  // try{
    return (
      <>
        <div className="hidden lg:block md:block container p-5">
          <Slider {...settings}>
            {myCollection.map((image, index) => (
              <div key={index}>
                <img src={`${image.imgPath[t("lang")]}`} alt={`Slide ${image.label}`} className="w-full" />
              </div>
            ))}
          </Slider>
        </div>
  
        <div className="lg:hidden md:hidden">
          <Slider {...settings}>
            {myCollection.map((image, index) => (
              <div key={index} className="w-full">
                <img
                  src={`${image.imgPath[t("lang")]}`}
                  alt={`Slide ${image.label}`}
                />
              </div>
            ))}
          </Slider>
        </div>
      </>
    );
  // }catch(e){
  //   console.log("error : ",e);
  //   return <>
  //   </>
  // }
}

export default FirstCarsoul;

// const MyCollection = [
//     {
//       label: "First Picture",
//       imgPath:
//         "https://ihyamiras.org/en/upload/arsiv/slayt-Facebook-New-08-K1DJHMAEPKHUC8X9U5BC.jpg",
//     },
//     {
//       label: "Second Picture",
//       imgPath:
//         "https://ihyamiras.org/en/upload/arsiv/slayt-Facebook-New-17-XZYBP20CQFF5GLE97I5P.jpg",
//     },
//     {
//       label: "Third Picture",
//       imgPath:
//         "https://ihyamiras.org/en/upload/arsiv/slayt-Proj-Cover-Photo-RC-Opening-copy-OIVHI3EV03ZVI5C2U3F6.jpg",
//     },
//   ];
