import IconBTN from "../../Components/shared/fancyBTNwithIcon";
import { useTranslation } from "react-i18next";

import MenuBookIcon from "@mui/icons-material/MenuBook";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import SchoolIcon from "@mui/icons-material/School";
import RoofingIcon from "@mui/icons-material/Roofing";
import PaidIcon from "@mui/icons-material/Paid";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import React, { useEffect, useState, useRef } from "react";
import { useSpring, animated } from '@react-spring/web';


function ShortCuts() {
  const { t, i18n } = useTranslation();
  const [isInView, setIsInView] = useState(false);
  const divRef = useRef(); // Create a ref for the animated div

  const springs = useSpring({
    from: { y: 500 },
    to: { y: isInView ? 0 : 500 }, // Only animate when the div is in view
    // delay: 500,
  });
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.unobserve(entry.target); // Stop observing once the animation is triggered
          }
        });
      },
      { threshold: 0.1 } // Adjust this value to control when the animation should trigger
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  return (
    <animated.div ref={divRef} className="my-5" style={{ ...springs }}>
      <div className="hidden lg:block">
        <div className="flex container items-center justify-center  p-4 max-w-90 max-w-90vw">
          <IconBTN
            Icon={AccountBalanceIcon}
            name={t("shortcuts.general.title")}
            route="/projects"
          />
          <IconBTN
            Icon={LunchDiningIcon}
            name={t("shortcuts.ramadan.title")}
            route="/project?p=keestheen"
          />
          <IconBTN
            Icon={PaidIcon}
            name={t("shortcuts.zakat.title")}
            route="/project?p=kfaletyteem"
          />
          <IconBTN
            Icon={RoofingIcon}
            name={t("shortcuts.restoration.title")}
            route="/project?p=iftarsaem"
          />
          <IconBTN
            Icon={MonitorHeartIcon}
            name={t("shortcuts.earthquake.title")}
            route="/project?p=slamkdseya"
          />
          <IconBTN
            Icon={MenuBookIcon}
            name={t("shortcuts.quran.title")}
            route="/project?p=kswteid"
          />
          <IconBTN
            Icon={SchoolIcon}
            name={t("shortcuts.education.title")}
            route="/project?p=zkatfitr"
          />
          <IconBTN
            Icon={ErrorOutlineIcon}
            name={t("shortcuts.emergency.title")}
            route="/project?p=keestheen"
          />
          <IconBTN
            Icon={VolunteerActivismIcon}
            name={t("shortcuts.sponsership.title")}
            route="/project?p=keestheen"
          />
        </div>
      </div>

      <div className="lg:hidden mt-5 px-4">
        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        <IconBTN
            Icon={AccountBalanceIcon}
            name={t("shortcuts.general.title")}
            route="/projects"
          />
          <IconBTN
            Icon={LunchDiningIcon}
            name={t("shortcuts.ramadan.title")}
            route="/project?p=keestheen"
          />
          <IconBTN
            Icon={PaidIcon}
            name={t("shortcuts.zakat.title")}
            route="/project?p=kfaletyteem"
          />
          <IconBTN
            Icon={RoofingIcon}
            name={t("shortcuts.restoration.title")}
            route="/project?p=iftarsaem"
          />
          <IconBTN
            Icon={MonitorHeartIcon}
            name={t("shortcuts.earthquake.title")}
            route="/project?p=slamkdseya"
          />
          <IconBTN
            Icon={MenuBookIcon}
            name={t("shortcuts.quran.title")}
            route="/project?p=kswteid"
          />
          <IconBTN
            Icon={SchoolIcon}
            name={t("shortcuts.education.title")}
            route="/project?p=zkatfitr"
          />
          <IconBTN
            Icon={ErrorOutlineIcon}
            name={t("shortcuts.emergency.title")}
            route="/project?p=keestheen"
          />
          <IconBTN
            Icon={VolunteerActivismIcon}
            name={t("shortcuts.sponsership.title")}
            route="/project?p=keestheen"
          />
        </div>
      </div>
    </animated.div>
  );
}

export default ShortCuts;
