import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import StickyMobileDonationBTNs from "../Components/MobileStickyDonation/mobileStikyDonation";
import StickyDonation from "../Components/MobileStickyDonation/StickyDonation";

function PageLayout({ children }) {
  return (
    <>
      <Header />
      {children}
      <Footer />
      <div className="lg:hidden md:hidden">
      </div>
      <StickyDonation></StickyDonation>
    </>
  );
}

export default PageLayout;
