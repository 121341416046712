import { useTranslation } from "react-i18next";

function PeopleDisplayer({ people, headerTitle1, headerTitle2 }) {
  const { t, i18n } = useTranslation();
  const lang = t("lang");

  return (
    <div className="overflow-x-auto container">
      <table className="table">
        {/* head */}
        <thead>
          <tr>
            <th>{headerTitle1[lang]}</th>
            <th>{headerTitle2[lang]}</th>
          </tr>
        </thead>

        <tbody>
          {/* people */}
          {people.map((item, index) => (
            <tr key={index}>
              <td>
                <div className="flex items-center gap-3">
                  <div className="avatar">
                    <div className="mask mask-squircle h-12 w-12">
                      <img src={item.img} alt="Avatar Tailwind CSS Component" />
                    </div>
                  </div>
                  <div>
                    <div className="font-bold">{item.name}</div>
                    <div className="text-sm opacity-50">{item.country}</div>
                  </div>
                </div>
              </td>
              <td>
                {item.details}
                <br />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PeopleDisplayer;
