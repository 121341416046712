import React, { useState } from "react";
import AdminSideBar from "./AdminSideBar";
import AdminPanelEditor from "./AdminPanelEditor";

const passwd = "password@aqsa.kadinlari";

function Admin() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [selectedPage, setSelectedPage] = useState("/");
  const [selectedPageName, setSelectedPageName] = useState("home");

  const handleLogin = (e) => {
    e.preventDefault();
    // Authenticate with username and password
    if (username === "admin" && password === passwd) {
      setIsLoggedIn(true);
    } else {
      setError("Invalid credentials. Please try again.");
    }
  };

  const handleButtonClick = (page, pname) => {
    setSelectedPage(page);
    setSelectedPageName(pname);
    console.log(pname);
  };

  // Render the login form if not logged in
  if (!isLoggedIn) {
    return (
      <div className="flex min-w-screen min-h-screen justify-center items-center h-screen">
        <form className="bg-white p-6 rounded shadow-md" onSubmit={handleLogin}>
          <h2 className="text-xl mb-4">Admin Login</h2>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <div className="mb-4">
            <label className="block text-gray-700">Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="border rounded w-full py-2 px-3"
              placeholder="Enter username"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border rounded w-full py-2 px-3"
              placeholder="Enter password"
            />
          </div>
          <button
            type="submit"
            className="bg-customPink text-white py-2 px-4 rounded hover:bg-blue-700"
          >
            Login
          </button>
        </form>
      </div>
    );
  }

  // Render the admin panel if logged in
  return (
    <div className="flex min-w-screen">
      <AdminSideBar onButtonClick={handleButtonClick} />
      <AdminPanelEditor page={selectedPage} pname={selectedPageName} />
    </div>
  );
}

export default Admin;
