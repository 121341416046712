import React from "react";
import { Link } from "react-router-dom";

function IconBTN({ Icon, name, route }) {
  return (
    <Link
      to={route}
      className="p-3 m-2 flex flex-col items-center justify-center bg-gray-100 rounded-lg  hover:bg-customOrange hover:text-white cursor-pointer"
      style={{
        textDecoration: "none",
        width:"auto"
      }}
    >
      {/* <div
    > */}
      <Icon className="flex justify-center" />
      <h3 className="mt-2 font-serif">{name}</h3>
      {/* </div> */}
    </Link>
  );
}

export default IconBTN;
