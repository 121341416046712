import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "../../api.json";

function ButtonLink({ name, route, sublinks }) {
  const { t, i18n } = useTranslation();
  const [inside, setInside] = useState(false);

  return (
    <div className="dropdown dropdown-hover">
      <Link
        to={route}
        onMouseEnter={() => setInside(true)}
        onMouseLeave={() => setInside(false)}
        style={{
          height: "40px",
          backgroundColor: inside ? "#c8158d" : "transparent",
          border: "none",
          padding: "0",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "background-color 0.3s ease",
          textDecoration: "none",
        }}
        className=""
      >
        <div className="flex items-center p-2">
          <p
            className="text-sm lg:text-xl mx-1"
            style={{ color: inside ? "#fff" : "#c8158d" }}
          >
            {name}
          </p>
        </div>
      </Link>
      {sublinks && sublinks.length > 0 ? (
        <ul
          tabIndex={0}
          className="dropdown-content menu bg-base-100 rounded-box z-40 w-52 p-2 shadow"
        >
          {sublinks.map((sublink, index) => (
            <li key={index}>
              <a href={sublink.route} style={{ textDecoration: "none" }}>
                {t(sublink.title[i18n.language])}
              </a>
            </li>
          ))}
        </ul>
      ) : (
        <br />
      )}
    </div>
  );
}

function Navlinks({ translateProvider }) {
  const [configData, setConfigData] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetch(`${api.url}/get/header`)
      .then((response) => response.json())
      .then((data) => setConfigData(data))
      .catch((error) => console.error("Error fetching config data:", error));
  }, []);

  if (!configData) {
    return <p></p>;
  }

  return (
    <div
      className="flex justify-center"
      style={{
        direction: t("dir"),
      }}
    >
      {configData.navlinks.map((item, len) => {
        return (
          <ButtonLink
            name={item.title[t("lang")]}
            route={item.route}
            sublinks={item.sublinks}
          />
        );
      })}

    </div>
  );
}

function NavlinksMobile({ translateProvider }) {
  const [configData, setConfigData] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetch(`${api.url}/get/header`) // Replace with your API endpoint
      .then((response) => response.json())
      .then((data) => setConfigData(data))
      .catch((error) => console.error("Error fetching config data:", error));
  }, []);

  if (!configData) {
    return <p></p>;
  }
  return (
    <ul className="menu bg-base-200 min-h-screen w-80 p-4 space-y-2">
      <li className="mx-auto">
        <a href="/">
          <img
            src={`/images/logo_${i18n.language}.svg`}
            style={{ maxWidth: 150 }}
            alt="Logo"
          />
        </a>
      </li>

      {configData.navlinks.map((item, len) => {
        return (
          <li>
            <ButtonLink
              name={item.title[t("lang")]}
              route={item.route}
              sublinks={item.sublinks}
            />
          </li>
        );
      })}
    </ul>
  );
}

export { Navlinks, NavlinksMobile };
