import PageLayout from "../PageLayout";
import "./BankAccounts.scss";
import { useTranslation } from "react-i18next";

function BankAccounts() {
  const { t, i18n } = useTranslation();

  function generateBanksHTML() {
    const bank_language = t("bank_accounts.lang");
    const bank_details = require(`./bank_details/${bank_language}.json`);
    return (
      <div>
        {bank_details.map((details, index) => {
          return (
            <table className="bank-table table table-striped table-bordered mt-5">
              <thead className="thead-light">
                <tr>
                  <th className="bank-name" colSpan={2}>
                    {details.bank_name}
                  </th>
                  <th className="bank-logo">
                    <img
                      src={details.logo}
                      width={120}
                      alt={details.bank_name}
                    ></img>
                  </th>
                </tr>
                <tr>
                  <th>{t("bank_accounts.currency")}</th>
                  <th>{t("bank_accounts.acount_number")}</th>
                  <th>{t("bank_accounts.iban")}</th>
                </tr>
              </thead>
              <tbody>
                {details.accounts.map((account) => {
                  return (
                    <tr>
                      <td>{account.name}</td>
                      <td>{account.account_number}</td>
                      <td>{account.iban}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </div>
    );
  }
  return (
    <>
      <div>
        <div className="flex flex-col justify-center items-center">
          <h1
            className="flex font-bold text-center mt-3 text-3xl text-customPink"
            style={{ direction: t("rtl") }}
          >
            {t("bank_accounts.page_title")}
          </h1>
        </div>
        <div className="container"></div>
      </div>
      <div className="container">{generateBanksHTML()}</div>
    </>
  );
}

export default BankAccounts;
