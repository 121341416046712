import PageLayout from "../PageLayout";
import PageGenerator from "../PageGenerator"; // Assuming this is a component
import React, { useState, useEffect } from "react";
import api from "../../api.json";
import Loading from "../../Components/Loading/Loading";


function ContactUS() {
  const [pageJson, setPageJson] = useState(null);

  const pageName = "contactus";
  useEffect(() => {
    fetch(`${api.url}/get/${pageName}`)
      .then((response) => response.json())
      .then((data) => {
        setPageJson(data);
      })
      .catch((error) => {
        console.error("Error fetching the page JSON:", error);
      });
  }, []);

  if (!pageJson) {
    return (
     <Loading />
    );
  }

  return (
    <>
      <PageGenerator pageContent={pageJson} />
    </>
  );
}

export default ContactUS;