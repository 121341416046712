import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./Pages/Home/Home";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import App from "./App";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import PageLayout from "./Pages/PageLayout";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {HashRouter as Router} from 'react-router-dom'

const firebaseConfig = {
  apiKey: "AIzaSyDvztl3CCok9s-yuvEqTU8InVkSxVscKZU",
  authDomain: "aksa-kadinlari.firebaseapp.com",
  projectId: "aksa-kadinlari",
  storageBucket: "aksa-kadinlari.appspot.com",
  messagingSenderId: "111476619529",
  appId: "1:111476619529:web:294a8d99ac1ac24cbc3bd8",
  measurementId: "G-DC8W4CN2JB",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const baseElement = document.getElementsByTagName("base")[0];
const baseUrl = baseElement ? baseElement.getAttribute("href") : "/";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router >
    <PageLayout>
      <App />
    </PageLayout>
  </Router>
);

reportWebVitals();
