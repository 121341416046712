import React, { useState } from "react";

import api from "../../api.json";

function FileUploader({ onFileLoaded }) {
  const [file, setFile] = useState(null); // Store the file

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]; // Get the first file selected
    if (selectedFile) {
      setFile(selectedFile); // Store the file in state
    }
  };

  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file); // Append the file to formData

    try {
      const response = await fetch(`${api.url}/upload`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        console.log("File uploaded successfully:", result);
        onFileLoaded(`${api.url}${result.filePath}`); // Pass the server response to parent
      } else {
        console.error("File upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div>
      <input
        type="file"
        className="file-input file-input-bordered file-input-primary w-full max-w-xs mt-2"
        onChange={handleFileChange} // Handle file input change
      />
      <button onClick={handleUpload} className="btn btn-primary mt-2">
        Upload
      </button>
    </div>
  );
}

export default FileUploader;





// import React, { useState } from "react";


// function FileUploader({ onFileLoaded }) {
//     const [base64, setBase64] = useState("");
  
//     const handleFileChange = (event) => {
//       const file = event.target.files[0]; // Get the first file selected
//       if (file) {
//         const reader = new FileReader();
//         reader.readAsDataURL(file); // Read the file as a data URL (Base64)
  
//         reader.onload = () => {
//           const result = `/download/${reader.result.fileName}`;
//           setBase64(result); // Store the Base64 string in state
//           onFileLoaded(result); // Pass the Base64 string to the parent component
//         };
  
//         reader.onerror = (error) => {
//           console.error("Error reading file:", error);
//         };
//       }
//     };
  
//     return (
//       <div>
//         <input
//           type="file"
//           className="file-input file-input-bordered file-input-primary w-full max-w-xs mt-2"
//           onChange={handleFileChange} // Handle file input change
//         />
//       </div>
//     );
//   }


//   export default FileUploader;