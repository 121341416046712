import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import api from "../../api.json";
import { useSpring, animated } from "@react-spring/web";
import { t } from "i18next";

function SaidThatCard({ title = {}, details = {}, img, video }) {
    const modalId = `my_modal_${title[t("lang")]}`; // Unique modal id for each card

    return (
    <div class="card shadow-xl m-2" style={{ minWidth: 250, height: 400 }}>
      <figure>
        <img src={img} alt="card-image" />
      </figure>
      <div class="card-body">
        <h2 class="card-title">{title[t("lang")]}</h2>

        <p>{details[t("lang")]}</p>
        <div class="card-actions justify-start">
          <button
            class="btn btn-primary  bg-customPink text-white "
            onClick={() => document.getElementById(modalId).showModal()}          >
            show
          </button>
        </div>
      </div>

      <dialog  id={modalId} className="modal">
        <div className="modal-box w-11/12 max-w-5xl">
          <h3 className="font-bold text-lg">{title[t("lang")]}</h3>
          <figure>
            <img src={img} alt="card-image" />
          </figure>
          <p className="py-4">{details[t("lang")]}</p>

          <div className="flex flex-col items-center justify-center p-2 rounded-md mr-5">
            <iframe
              src={video}
              className="w-full"
              style={{ minHeight: 200, borderRadius: "15px" }}
              title={`Promotion Video `}
            />
          </div>
          <div className="modal-action">
            <form method="dialog">
              {/* if there is a button, it will close the modal */}
              <button className="btn">Close</button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  );
}

function SaidThat() {
  const [isInView, setIsInView] = useState(false);
  const divRef = useRef(); // Create a ref for the animated div
  const { t, i18n } = useTranslation();

  const springs = useSpring({
    from: { y: 500 },
    to: { y: isInView ? 0 : 500 }, // Only animate when the div is in view
    // delay: 500,
  });

  const [myCollection, setMyCollection] = useState([]); // Initialize the state

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await fetch(`${api.url}/get/saidthat`);
        const data = await response.json();
        console.log("API Response:", data); // Log the response to debug
        if (data && data.body) {
          setMyCollection(data); // Ensure you're accessing the correct property
        } else {
          console.error("Unexpected API response structure", data);
        }
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    };

    fetchCampaigns();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.unobserve(entry.target); // Stop observing once the animation is triggered
          }
        });
      },
      { threshold: 0.1 } // Adjust this value to control when the animation should trigger
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);



  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
  };

  const settings3 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <animated.div ref={divRef} className="my-5" style={{ ...springs }}>
      {/* the people here */}
      <div className="hidden md:block lg:block">
        <div className="flex flex-col items-center container">
          <h1 className="card-title py-2 text-customPink font-bold text-3xl">
            {myCollection.title ? myCollection.title[t("lang")] : ""}
          </h1>
          <div className="container">
            <Slider {...settings}>
              {myCollection.body
                ? myCollection.body.map((card, index) => (
                    <SaidThatCard
                      key={index}
                      title={card.title}
                      details={card.details}
                      img={card.img?.en}
                      video={card.video}
                    />
                  ))
                : null}
            </Slider>
          </div>
        </div>
      </div>

      <div className="block lg:hidden md:hidden sm:block my-5">
        <div className="flex flex-col items-center container">
          <h1 className="card-title my-2 font-bold text-3xl text-customPink">
            {myCollection.title ? myCollection.title[t("lang")] : ""}
          </h1>
          <div className="container">
            <Slider {...settings3}>
            {myCollection.body
                ? myCollection.body.map((card, index) => (
                    <SaidThatCard
                      key={index}
                      title={card.title}
                      details={card.details}
                      img={card.img?.en}
                      video={card.video}
                    />
                  ))
                : null}
            </Slider>
          </div>
        </div>
      </div>
    </animated.div>
  );
}

export default SaidThat;
