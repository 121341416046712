import { Link } from "react-router-dom";

function ProjectCard({
  img = "",
  title = "",
  hoverColor = "bg-black",
  overlayColor = "",
  route = "/",
}) {
  return (
    <Link
      to={route}
      className="relative flex flex-col rounded-md items-center justify-center m-2"
      style={{
        width: "280px",
        height: "280px",
        textDecoration: "none",
      }}
    >
      <div
        className="relative flex flex-col rounded-md items-center justify-center w-full h-full"
        style={{
          backgroundImage: `url('${img}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h1 className="relative z-10 font-bold text-white">{title}</h1>
        <div
          className={`absolute inset-0 ${"hover:"+hoverColor} rounded-md ${overlayColor}`}
          style={{ opacity: "40%" }}
        ></div>
      </div>
    </Link>
  );
}

export default ProjectCard;
