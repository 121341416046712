import { useTranslation } from "react-i18next";
import { Banner, BannerMobile } from "./banner1";
import Banner2 from "./banner2";
import { Navlinks, NavlinksMobile } from "./navlinks";

/***
 * header will be divided into 3 parts
 * Banner-> login logout follow
 * Banner2 2 -> logo + btns
 * navlinks -> links
 */
function Header() {
  const { t, i18n } = useTranslation();
  console.log(window.location.hash )
  if (window.location.hash === '#/admin') {
    return <></>;
  }

  return (
    <div>
      <div className="hidden flex-col lg:block md:block sm:hidden">
        <Banner trsndlateProvider={t} i18n={i18n} />
        <Banner2 t={t} i18n={i18n} />
        <Navlinks trsndlateProvider={t} />
      </div>

      <div className="block lg:hidden md:hidden sm:block">
        <BannerMobile trsndlateProvider={t} i18n={i18n}>
          <NavlinksMobile trsndlateProvider={t} />
        </BannerMobile>
      </div>
    </div>
  );
}

export default Header;
