function Banner2({ t, i18n }) {
  return (
    <div className="flex items-center justify-center ">
      {/* <div className="w-full"> </div> */}
      <div className="">
        <img
          src={"/images/logo_" + i18n.language + ".svg"}
          style={{ maxWidth: 160, maxHeight: 120 }}
          className="flex"
        />
      </div>
      {/* <div className="flex justify-end self-center w-full p-5">
        <button
          className="btn  btn-outlined mx-1  text-white"
          style={{ backgroundColor: "#c8158d" }}
        >
          {t("banner2.donate")}{" "}
        </button>
        <button className="btn" style={{ backgroundColor: "#f7a01a" }}>
          {t("banner2.basket")}
        </button>
      </div> */}
    </div>
  );
}

export default Banner2;
