import PageLayout from "../PageLayout";
import PageGenerator from "../PageGenerator"; // Assuming this is a component
import React, { useState, useEffect } from "react";
import api from "../../api.json";
import Loading from "../../Components/Loading/Loading";
import { Link, useLocation } from "react-router-dom";

function Policies() {
  const [pageJson, setPageJson] = useState(null);
 // Parse the query parameters
 const location = useLocation();
 const searchParams = new URLSearchParams(location.search);
 const pageName = searchParams.get("p"); // This will give you the value of the 'v' parameter

  useEffect(() => {
    fetch(`${api.url}/get/${pageName}`)
      .then((response) => response.json())
      .then((data) => {
        setPageJson(data);
      })
      .catch((error) => {
        console.error("Error fetching the page JSON:", error);
      });
  }, []);

  if (!pageJson) {
    return (
     <Loading />
    );
  }

  return (
    <>
      <PageGenerator pageContent={pageJson} />
    </>
  );
}

export default Policies;
