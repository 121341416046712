import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import "../../App.scss";

function StickyDonation() {
  const [projects] = useState([]);
  const [donationActive, setDonationActive] = useState(false);
  useEffect(() => {
    getDocs(collection(db, "Projects")).then((snapshot) => {
      for (var i in snapshot.docs) {
        var obj = snapshot.docs[i].data();
        if (projects.filter((entry) => entry.id === obj.id).length === 0) {
          projects.push(obj);
        }
      }
    });
  }, []);
  const { t, i18n } = useTranslation();


  return (
    <div className="app" dir={t("dir")}>
      <div
        className={"quick-donation-bubble " + (donationActive ? "active" : "")}
        dir={t("dir")}
      >
        <div
          className="bubble"
          onClick={() => {
            setDonationActive(!donationActive);
          }}
        >
          {t("donation.title")} <br />
          {t("donation.subtitle")}
        </div>
        <div className="form">
          <div className="form-group">
            <label className="control-label">
              {t("donation.form.project_name")}
            </label>
            <select className="form-control">
              <option>{t("donation.form.select_project")}</option>
              {projects.map((item, index) => {
                return (
                  <option value={item.id}>
                    {item["name_" + i18n.language]}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group">
            <label className="control-label">{t("donation.form.amount")}</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">₺</span>
              </div>
              <input
                type="text"
                className="form-control"
                value={100}
                aria-label="Amount (to the nearest dollar)"
              />
              <div className="input-group-append">
                <span className="input-group-text">.00</span>
              </div>
            </div>
          </div>
          <div className="form-group action">
            <input
              type="button"
              className="btn btn-primary"
              value={t("donation.form.donate")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StickyDonation;
