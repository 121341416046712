import PageLayout from "../PageLayout";
import PageGenerator from "../PageGenerator"; // Assuming this is a component
import React, { useState, useEffect } from "react";
import api from "../../api.json";
import Loading from "../../Components/Loading/Loading";

function Projects() {
  const [pageJson, setPageJson] = useState(null);
  const [error, setError] = useState(null);
  const pageName = "projects";

  // Function to fetch data with retry logic
  const fetchWithRetry = (url, retries = 3) => {
    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch ${pageName} page: ${response.statusText}`);
        }
        return response.json();
      })
      .catch((error) => {
        if (retries > 1) {
          return fetchWithRetry(url, retries - 1);
        } else {
          throw error;
        }
      });
  };

  useEffect(() => {
    fetchWithRetry(`${api.url}/get/${pageName}`)
      .then((data) => {
        setPageJson(data);
      })
      .catch((error) => {
        console.error("Error fetching the page JSON:", error);
        setError(error.message);
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!pageJson) {
    return <Loading />;
  }

  return (
    <>
      <PageGenerator pageContent={pageJson} />
    </>
  );
}

export default Projects;
