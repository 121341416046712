import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PageLayout from "../PageLayout";
import api from "../../api.json";
import Loading from "../../Components/Loading/Loading";
import { useTranslation } from "react-i18next";


const firstName = {
  ar: "الاسم الاول",
  en: "first name",
  tr: "ilk adı",
};

const lastName = {
  ar: "اسم العائلة",
  en: "last name",
  tr: "soy isim",
};

const birthdate = {
  ar: "تاريخ الميلاد",
  en: "birthdate",
  tr: "doğum tarihi",
};

const country = {
  ar: "البلد",
  en: "country",
  tr: "ülke",
};

const city = {
  ar: "المدينة",
  en: "city",
  tr: "şehir",
};

const address = {
  ar: "العنوان",
  en: "address",
  tr: "adres",
};

const email = {
  ar: "البريد الإلكتروني",
  en: "email",
  tr: "e-posta",
};

const phone = {
  ar: "رقم الهاتف",
  en: "phone",
  tr: "telefon",
};

const education = {
  ar: "التعليم",
  en: "education",
  tr: "eğitim",
};

const currentJob = {
  ar: "الوظيفة الحالية",
  en: "current job",
  tr: "mevcut iş",
};

const person1 = {
  ar: "الشخص الأول",
  en: "person 1",
  tr: "kişi 1",
};

const person2 = {
  ar: "الشخص الثاني",
  en: "person 2",
  tr: "kişi 2",
};

const type = {
  ar: " النوع التطوع",
  en: "type of service",
  tr: "tür",
};
const postingOnSocialMedia = {
  ar: "النشر على وسائل التواصل الاجتماعي",
  en: "Posting on Social Media",
  tr: "Sosyal Medyada Paylaşım",
};
const volunteering = {
  ar: "التطوع",
  en: "Volunteering",
  tr: "Gönüllülük",
};
const programmingAndDevelopment = {
  ar: "البرمجة والتطوير",
  en: "Programming and development",
  tr: "Programlama ve geliştirme",
};

const enterNamesOfTwoPersons = {
  ar: "أدخل أسماء شخصين يعرفانك في بلدك",
  en: "Enter names of 2 persons that know you in your country",
  tr: "Ülkenizde sizi tanıyan 2 kişinin adını girin"
};


function Volunteers() {
  const pageName = "volunteers";
  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    Birthdate: "",
    Country: "",
    City: "",
    Address: "",
    email: "",
    Phone: "",
    education: "",
    currentJob: "",
    person1: "",
    person2: "",
    type: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // GET request to fetch existing volunteers
      const response = await fetch(`${api.url}/get/volunteers`);
      const result = await response.json();

      if (!response.ok) throw new Error("Failed to fetch existing data");

      // Adding the new volunteer to the fetched data
      const updatedBody = [
        ...result.body,
        {
          ...formData,
          Birthdate: startDate ? startDate.toLocaleDateString("en-GB") : "", // format date to dd/mm/yyyy
        },
      ];

      // POST request to update the volunteer data
      const postResponse = await fetch(`${api.url}/set/volunteers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          category: "home",
          body: updatedBody,
        }),
      });

      if (!postResponse.ok) throw new Error("Failed to submit data");

      setLoading(false);
      alert("Volunteer information submitted successfully!");
    } catch (error) {
      setLoading(false);
      console.error("There was an error submitting the data!", error);
      alert("Failed to submit volunteer information.");
    }
  };

  return (
    <>
      <div className="container max-w-full md:max-w-md lg:max-w-sm">
        <form onSubmit={handleSubmit}>
          {/* First Name */}
          <label className="input input-bordered flex items-center gap-2 w-full">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="Name w-full"
              placeholder={firstName[t("lang")]}
              required
            />
          </label>

          {/* Last Name */}
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="grow"
              placeholder={lastName[t("lang")]}
              required
            />
          </label>

          {/* BirthDate */}
          <label className="input input-bordered flex items-center gap-2">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText={birthdate[t("lang")]}
              className="Adress"
              dateFormat="dd/MM/yyyy"
              required
            />
          </label>

          {/* Country */}
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              name="Country"
              value={formData.Country}
              onChange={handleChange}
              className="Telephone"
              placeholder={country[t("lang")]}
              required
            />
          </label>

          {/* City */}
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              name="City"
              value={formData.City}
              onChange={handleChange}
              className="Previous experiences"
              placeholder={city[t("lang")]}
              required
            />
          </label>

          {/* Address */}
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              name="Address"
              value={formData.Address}
              onChange={handleChange}
              className="religion"
              placeholder={address[t("lang")]}
              required
            />
          </label>

          {/* E-mail */}
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="how will you benefit us"
              placeholder={email[t("lang")]}
              required
            />
          </label>

          {/* Phone */}
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              name="Phone"
              value={formData.Phone}
              onChange={handleChange}
              className="how will you benefit us"
              placeholder={phone[t("lang")]}
              required
            />
          </label>

          {/* Education */}
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              name="education"
              value={formData.education}
              onChange={handleChange}
              className="how will you benefit us"
              placeholder={education[t("lang")]}
              required
            />
          </label>

          {/* Current Job */}
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              name="currentJob"
              value={formData.currentJob}
              onChange={handleChange}
              className="how will you benefit us"
              placeholder={currentJob[t("lang")]}
              required
            />
          </label>

          <h1 className="text-customOrange">
            {enterNamesOfTwoPersons[t("lang")]}
          </h1>

          {/* Person1 */}
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              name="person1"
              value={formData.person1}
              onChange={handleChange}
              className="how will you benefit us"
              placeholder={person1[t("lang")]}
              required
            />
          </label>

          {/* Person2 */}
          <label className="input input-bordered flex items-center gap-2">
            <input
              type="text"
              name="person2"
              value={formData.person2}
              onChange={handleChange}
              className="how will you benefit us"
              placeholder={person2[t("lang")]}
              required
            />
          </label>

          {/* Volunteering Type */}
          <select
            name="type"
            value={formData.type}
            onChange={handleChange}
            className="select select-bordered w-full max-w-xs"
            required
          >
            <option value="" disabled selected>
            {type[t("lang")]}
            </option>
            <option value="Posting on Social Media">
            {postingOnSocialMedia[t("lang")]}
            </option>
            <option value="Volunteering">
              {volunteering[t("lang")]}
            </option>
            <option value="Programming and development">
            {programmingAndDevelopment[t("lang")]}
            </option>
          </select>

          <input
            type="submit"
            value="Submit"
            className="bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded shadow-md transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer mt-4 mb-4"
          />
        </form>
      </div>
      {loading && <Loading />}
    </>
  );
}

export default Volunteers;
