import translationEN from "./locales/en/translation.json";
import translationAR from "./locales/ar/translation.json";
import translationTR from "./locales/tr/translation.json";
const paths = [
  {
    ar: "./Components/Header/locales/ar.json",
    en: "./Components/Header/locales/en.json",
    tr: "./Components/Header/locales/tr.json",
  },
];

async function translationBuilder(lang) {
  for (const item of paths) {
    console.log(item.ar);
    
    const [arTranslation, enTranslation, trTranslation] = await Promise.all([
      require(item.ar),
      require(item.en),
      require(item.tr),
    ]);

    translationAR.push(...arTranslation.default);
    translationEN.push(...enTranslation.default);
    translationTR.push(...trTranslation.default);
  }
  if (lang === "en") {
    return translationEN;
  }
  if (lang === "ar") {
    return translationAR;
  }
  if (lang === "tr") {
    return translationTR;
  }
}

// loadAndAppendTranslations();

export default translationBuilder;
