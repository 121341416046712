import React from "react";
import { useTranslation } from "react-i18next";

function ArticleWithImg({ background, src, children }) {
  const { t, i18n } = useTranslation();

  return (
    <div className="container">
      <div
        class="hero min-h-60 "
        style={{
          direction: `${t("dir")}`,
          backgroundImage: `url(${background})`,
        }}
      >
        <div
          class="hero-content flex-col lg:flex-row-reverse"
          style={{ direction: `${t("dir")}` }}
        >
          <img
            src={src}
            class=" md:min-w-screen rounded-lg shadow-2x "
            style={{ maxWidth: "400px" }}
          />
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}

function ArticleNormal({ background, layout, children }) {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`flex flex-col p-5 ${layout}`}
      style={{
        backgroundImage: `url(${background})`,
        direction: `${t("dir")}`,
      }}
    >
      {children}
    </div>
  );
}

function CArticle({
  background = "",
  layout = "",
  spacing = "",
  isImage = "",
  img = "",
  children,
}) {
  return isImage ? (
    <ArticleWithImg src={img} background={background}>
      {children}
    </ArticleWithImg>
  ) : (
    <ArticleNormal background={background} layout={layout + " " + spacing}>
      {children}{" "}
    </ArticleNormal>
  );
}

export default CArticle;
