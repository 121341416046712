import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FileUploader from "../../Components/FileUploader/fileUploader";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

function NewItem({ children }) {
  return (
    <div>
      <button
        className="btn hover:bg-customOrange"
        onClick={() => document.getElementById("my_modal_5").showModal()}
      >
        Add New Item
      </button>

      <dialog id="my_modal_5" className="modal">
        <div className="modal-box flex flex-col items-center justify-center">
          {children}
        </div>
      </dialog>
    </div>
  );
}

function HomeEditorCTranslator({ pageName, data, setData, onSave }) {
  const [reload, setReload] = useState(false);
  const [newItem, setNewItem] = useState({
    id: "",
    label: "",
    imgPath: { ar: "", en: "", tr: "" },
  });
  const [campaignItem, setCampaignItem] = useState({
    id: "",
    title: { ar: "", en: "", tr: "" },
    details: { ar: "", en: "", tr: "" },
    img: { ar: "", en: "", tr: "" },
  });

  const [SaidThatItem, setSaidThatItem] = useState({
    id: "",
    title: { ar: "", en: "", tr: "" },
    details: { ar: "", en: "", tr: "" },
    img: { ar: "", en: "", tr: "" },
    video: "",
  });
  const [promotionItem, setpromotionItem] = useState({
    id: "",
    title: "",
    details: "",
    src: "",
  });

  const { t, i18n } = useTranslation(); // Get i18n object

  useEffect(() => {
    if (reload) {
      // Simulate data fetch or re-render the content
      for (let i = 0; i < 500; i++) {
        // delay
      }
      setReload(false);
    }
  }, [reload]);

  function generateRandomId() {
    const chars = "0123456789abcdef";
    let id = "";
    for (let i = 0; i < 24; i++) {
      id += chars[Math.floor(Math.random() * chars.length)];
    }
    return id;
  }

  const onDelete = (itemId) => {
    closeModal();
    const updatedData = data;
    updatedData.body = updatedData.body.filter((item) => item.id !== itemId);
    setData(updatedData);
    onSave();
    setReload(true); // Trigger reload
  };

  const closeModal = () => {
    document.getElementById("my_modal_5").close();
  };

  const onAdd = (item) => {
    closeModal();
    item.id = generateRandomId();
    const updatedData = { ...data };
    updatedData.body.push(item);
    setData(updatedData);
    onSave();
    setReload(true); // Trigger reload
  };

  let content;

  switch (pageName) {
    case "cursoul": {
      content = (
        <div className="m-3">
          <h1>Carousel Images</h1>
          {data?.body?.length > 0 ? (
            data.body.map((item, index) => (
              <div key={index} className="m-5 space-y-3">
                <h1>{item.label}</h1>
                <img
                  src={item.imgPath?.en || "default-en.jpg"}
                  alt={`${item.label} - English`}
                />
                <img
                  src={item.imgPath?.ar || "default-ar.jpg"}
                  alt={`${item.label} - Arabic`}
                />
                <img
                  src={item.imgPath?.tr || "default-tr.jpg"}
                  alt={`${item.label} - Turkish`}
                />
                <button className="btn" onClick={() => onDelete(item.id)}>
                  Remove
                </button>
              </div>
            ))
          ) : (
            <p>No items available</p>
          )}

          <NewItem>
            <input
              type="text"
              placeholder="Label"
              className="input w-full max-w-xs mt-2"
              value={newItem.label}
              onChange={(e) =>
                setNewItem({ ...newItem, label: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Image for English"
              className="input w-full max-w-xs mt-2"
              value={newItem.imgPath?.en || ""}
              onChange={(e) =>
                setNewItem({
                  ...newItem,
                  imgPath: { ...newItem.imgPath, en: e.target.value },
                })
              }
            />
            <input
              type="text"
              placeholder="Image for Arabic"
              className="input w-full max-w-xs mt-2"
              value={newItem.imgPath?.ar || ""}
              onChange={(e) =>
                setNewItem({
                  ...newItem,
                  imgPath: { ...newItem.imgPath, ar: e.target.value },
                })
              }
            />
            <input
              type="text"
              placeholder="Image for Turkish"
              className="input w-full max-w-xs mt-2"
              value={newItem.imgPath?.tr || ""}
              onChange={(e) =>
                setNewItem({
                  ...newItem,
                  imgPath: { ...newItem.imgPath, tr: e.target.value },
                })
              }
            />
            <h1>or select English img file</h1>
            <FileUploader
              onFileLoaded={(base64) =>
                setNewItem({
                  ...newItem,
                  imgPath: { ...newItem.imgPath, en: base64 },
                })
              }
            />

            <h1>or select Arabic img file</h1>
            <FileUploader
              onFileLoaded={(base64) =>
                setNewItem({
                  ...newItem,
                  imgPath: { ...newItem.imgPath, ar: base64 },
                })
              }
            />

            <h1>or select Turkish img file</h1>
            <FileUploader
              onFileLoaded={(base64) =>
                setNewItem({
                  ...newItem,
                  imgPath: { ...newItem.imgPath, tr: base64 },
                })
              }
            />

            <button className="btn mt-2" onClick={() => onAdd(newItem)}>
              Save
            </button>
            <button className="btn mt-2" onClick={closeModal}>
              Cancel
            </button>
          </NewItem>
        </div>
      );
      return content;
    }

    case "campaigns": {
      content = (
        <div className="m-3">
          <h1>Campaigns</h1>
          {data?.body && data.body.length > 0 ? (
            data.body.map((item, index) => (
              <div key={index} className="m-5 space-y-3">
                <div className="p-5 rounded-lg bg-gray-400 shadow-lg">
                  {/* Images for different languages */}
                  <img
                    src={item.img?.en}
                    className="rounded-lg"
                    alt="English image"
                  />
                  <img
                    src={item.img?.ar}
                    className="rounded-lg"
                    alt="Arabic image"
                  />
                  <img
                    src={item.img?.tr}
                    className="rounded-lg"
                    alt="Turkish image"
                  />

                  {/* Title in a specific language */}
                  <p>title in arabic</p>
                  <h1 className="text-2xl font-bold text-customPink">
                    {item.title?.ar || ""}{" "}
                    {/* Adjust the language here as needed */}
                  </h1>

                  <p>title in english</p>
                  <h1 className="text-2xl font-bold text-customPink">
                    {item.title?.en || ""}{" "}
                    {/* Adjust the language here as needed */}
                  </h1>

                  <p>title in turkish</p>
                  <h1 className="text-2xl font-bold text-customPink">
                    {item.title?.tr || ""}{" "}
                    {/* Adjust the language here as needed */}
                  </h1>

                  {/* ************* details ************ */}
                  <p>details in arabic</p>
                  <h1 className="text-sm font-bold text-customOrange">
                    {item.details?.ar || ""}
                  </h1>

                  <p>details in english</p>
                  <h1 className="text-sm font-bold text-customOrange">
                    {item.details?.en || ""}
                  </h1>

                  <p>details in turkih</p>
                  <h1 className="text-sm font-bold text-customOrange">
                    {item.details?.tr || ""}
                  </h1>
                </div>
                <button className="btn" onClick={() => onDelete(item.id)}>
                  Remove
                </button>
              </div>
            ))
          ) : (
            <p>No items available</p>
          )}

          <NewItem>
            {/* Input fields for each language-specific image */}
            <input
              type="text"
              placeholder="Image Path for English"
              className="input w-full max-w-xs mt-2"
              value={campaignItem.img?.en || ""}
              onChange={(e) =>
                setCampaignItem({
                  ...campaignItem,
                  img: { ...campaignItem.img, en: e.target.value },
                })
              }
            />
            {/* File Uploaders for each image */}
            <FileUploader
              onFileLoaded={(base64) =>
                setCampaignItem({
                  ...campaignItem,
                  img: { ...campaignItem.img, en: base64 },
                })
              }
            />

            <input
              type="text"
              placeholder="Image Path for Arabic"
              className="input w-full max-w-xs mt-2"
              value={campaignItem.img?.ar || ""}
              onChange={(e) =>
                setCampaignItem({
                  ...campaignItem,
                  img: { ...campaignItem.img, ar: e.target.value },
                })
              }
            />
            <FileUploader
              onFileLoaded={(base64) =>
                setCampaignItem({
                  ...campaignItem,
                  img: { ...campaignItem.img, ar: base64 },
                })
              }
            />

            <input
              type="text"
              placeholder="Image Path for Turkish"
              className="input w-full max-w-xs mt-2"
              value={campaignItem.img?.tr || ""}
              onChange={(e) =>
                setCampaignItem({
                  ...campaignItem,
                  img: { ...campaignItem.img, tr: e.target.value },
                })
              }
            />
            <FileUploader
              onFileLoaded={(base64) =>
                setCampaignItem({
                  ...campaignItem,
                  img: { ...campaignItem.img, tr: base64 },
                })
              }
            />

            {/* Title  */}
            <input
              type="text"
              placeholder="Title in arabic"
              className="input w-full max-w-xs mt-2"
              value={campaignItem.title.ar || ""}
              onChange={(e) =>
                setCampaignItem({
                  ...campaignItem,
                  title: { ...campaignItem.title, ar: e.target.value },
                })
              }
            />

            <input
              type="text"
              placeholder="Title in english"
              className="input w-full max-w-xs mt-2"
              value={campaignItem.title.en || ""}
              onChange={(e) =>
                setCampaignItem({
                  ...campaignItem,
                  title: { ...campaignItem.title, en: e.target.value },
                })
              }
            />

            <input
              type="text"
              placeholder="Title in turkish"
              className="input w-full max-w-xs mt-2"
              value={campaignItem.title.tr || ""}
              onChange={(e) =>
                setCampaignItem({
                  ...campaignItem,
                  title: { ...campaignItem.title, tr: e.target.value },
                })
              }
            />

            {/* details */}
            <input
              type="text"
              placeholder="Details in arabic"
              className="input w-full max-w-xs mt-2"
              value={campaignItem.details.ar || ""}
              onChange={(e) =>
                setCampaignItem({
                  ...campaignItem,
                  details: { ...campaignItem.details, ar: e.target.value },
                })
              }
            />

            <input
              type="text"
              placeholder="Details in english"
              className="input w-full max-w-xs mt-2"
              value={campaignItem.details.en || ""}
              onChange={(e) =>
                setCampaignItem({
                  ...campaignItem,
                  details: { ...campaignItem.details, en: e.target.value },
                })
              }
            />

            <input
              type="text"
              placeholder="Details in turkish"
              className="input w-full max-w-xs mt-2"
              value={campaignItem.details.tr || ""}
              onChange={(e) =>
                setCampaignItem({
                  ...campaignItem,
                  details: { ...campaignItem.details, tr: e.target.value },
                })
              }
            />

            {/* Buttons */}
            <button className="btn mt-2" onClick={() => onAdd(campaignItem)}>
              Save
            </button>
            <button className="btn mt-2" onClick={closeModal}>
              Cancel
            </button>
          </NewItem>
        </div>
      );
      return content;
    }

    case "promotionFilms": {
      content = (
        <div className="m-3">
          <h1>Promotion Films</h1>
          {data?.body && data.body.length > 0 ? (
            data.body.map((item, index) => (
              <div key={index} className="m-5 space-y-3">
                <div className="p-5 rounded-lg bg-gray-400 shadow-lg">
                  <iframe
                    src={item.src}
                    className="w-full"
                    style={{ minHeight: 200, borderRadius: "15px" }}
                  />
                  <h1 className="text-2xl font-bold text-customPink">
                    {item.title}
                  </h1>
                  <h1 className="text-sm font-bold text-customOrange">
                    {item.details}
                  </h1>
                </div>
                <button className="btn" onClick={() => onDelete(item.id)}>
                  Remove
                </button>
              </div>
            ))
          ) : (
            <p>No items available</p>
          )}

          <NewItem>
            <input
              type="text"
              placeholder="Video Source Link"
              className="input w-full max-w-xs mt-2"
              value={promotionItem.src}
              onChange={(e) =>
                setpromotionItem({ ...promotionItem, src: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Title"
              className="input w-full max-w-xs mt-2"
              value={promotionItem.title}
              onChange={(e) =>
                setpromotionItem({ ...promotionItem, title: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Details"
              className="input w-full max-w-xs mt-2"
              value={promotionItem.details}
              onChange={(e) =>
                setpromotionItem({ ...promotionItem, details: e.target.value })
              }
            />
            <button className="btn mt-2" onClick={() => onAdd(promotionItem)}>
              Save
            </button>
            <button className="btn mt-2" onClick={closeModal}>
              Cancel
            </button>
          </NewItem>
        </div>
      );
      return content;
    }

    case "header": {
      function handleContentChange(lang, value, route) {
        const updatedNavlinks = data.navlinks.map((item) => {
          if (item.route === route) {
            return {
              ...item,
              title: {
                ...item.title,
                [lang]: value, // Update the specific language (e.g., "tr")
              },
            };
          }
          // If the item has sublinks, you need to update them as well
          if (item.sublinks) {
            const updatedSublinks = item.sublinks.map((subItem) => {
              if (subItem.route === route) {
                return {
                  ...subItem,
                  title: {
                    ...subItem.title,
                    [lang]: value,
                  },
                };
              }
              return subItem;
            });
            return { ...item, sublinks: updatedSublinks };
          }
          return item;
        });

        setData({ ...data, navlinks: updatedNavlinks });
        //onSave();
      }

      function handleContentChange2(value, route) {
        const updatedNavlinks = data.navlinks.map((item) => {
          // Update the main navlink route
          if (item.route === route) {
            return {
              ...item,
              route: value, // Update the route
            };
          }

          // If the item has sublinks, update the relevant sublink route
          if (item.sublinks) {
            const updatedSublinks = item.sublinks.map((subItem) => {
              if (subItem.route === route) {
                return {
                  ...subItem,
                  route: value, // Update the route of the sublink
                };
              }
              return subItem;
            });

            return { ...item, sublinks: updatedSublinks };
          }

          return item;
        });

        setData({ ...data, navlinks: updatedNavlinks });
      }

      function handleDelete(idx, subIdx = null) {
        const updatedNavlinks = data.navlinks
          .map((item, itemIdx) => {
            if (itemIdx === idx) {
              if (subIdx !== null && item.sublinks) {
                // Removing a sublink
                const updatedSublinks = item.sublinks.filter(
                  (_, subItemIdx) => subItemIdx !== subIdx
                );
                return { ...item, sublinks: updatedSublinks };
              }
              return null; // Mark for deletion
            }
            return item;
          })
          .filter((item) => item !== null); // Remove nulls

        setData({ ...data, navlinks: updatedNavlinks });
      }

      function handleAdd(idx) {
        const newSublink = {
          title: { ar: "sample", en: "sample", tr: "sample" },
          route: "",
          sublinks: [],
        };
        let tmp = data;
        try {
          if (tmp.navlinks[idx].sublinks) {
            tmp.navlinks[idx].sublinks.push(newSublink);
            setData(tmp);
            onSave();
            return;
          }
        } catch (e) {
          tmp.navlinks.push(newSublink);
          setData(tmp);
          onSave();
        }
      }

      try {
        content = (
          <div className="m-3">
            <h1>Header</h1>

            {data.navlinks.map((itm, idx) => {
              return (
                <div className="m-2 shadow-lg bg-white p-5 space-y-3 rounded-md">
                  <h1>Arabic</h1>
                  <input
                    value={itm.title.ar}
                    onChange={(e) =>
                      handleContentChange("ar", e.target.value, itm.route)
                    }
                    className="textarea textarea-bordered textarea-md w-full"
                  ></input>

                  <h1>English</h1>
                  <input
                    value={itm.title.en}
                    onChange={(e) =>
                      handleContentChange("en", e.target.value, itm.route)
                    }
                    className="textarea textarea-bordered textarea-md w-full"
                  ></input>

                  <h1>Turkish</h1>
                  <input
                    value={itm.title.tr}
                    onChange={(e) =>
                      handleContentChange("tr", e.target.value, itm.route)
                    }
                    className="textarea textarea-bordered textarea-md w-full"
                  ></input>

                  <h1>Route</h1>
                  <input
                    value={itm.route}
                    onChange={(e) =>
                      handleContentChange2(e.target.value, itm.route)
                    }
                    className="textarea textarea-bordered textarea-md w-full"
                  />
                  <button
                    className="btn btn-wide self-center hover:bg-customPink hover:text-white"
                    onClick={() => handleDelete(idx)}
                  >
                    Delete
                  </button>

                  <button
                    className="btn btn-wide self-center hover:bg-customPink hover:text-white"
                    onClick={() => handleAdd(idx)}
                  >
                    Add
                  </button>
                  <button
                    className="btn btn-wide self-center hover:bg-customPink hover:text-white"
                    onClick={onSave}
                  >
                    Save
                  </button>
                  {itm.sublinks.map((subItem, subIdx) => {
                    return (
                      <div className="m-2 shadow-lg bg-gray-200  p-5 space-y-3 rounded-md">
                        <h1>Arabic</h1>
                        <input
                          value={subItem.title.ar}
                          onChange={(e) =>
                            handleContentChange(
                              "ar",
                              e.target.value,
                              subItem.route
                            )
                          }
                          className="textarea textarea-bordered textarea-md w-full"
                        ></input>

                        <h1>English</h1>
                        <input
                          value={subItem.title.en}
                          onChange={(e) =>
                            handleContentChange(
                              "en",
                              e.target.value,
                              subItem.route
                            )
                          }
                          className="textarea textarea-bordered textarea-md w-full"
                        ></input>

                        <h1>Turkish</h1>
                        <input
                          value={subItem.title.tr}
                          onChange={(e) =>
                            handleContentChange(
                              "tr",
                              e.target.value,
                              subItem.route
                            )
                          }
                          className="textarea textarea-bordered textarea-md w-full"
                        ></input>

                        <h1>Route</h1>
                        <input
                          value={subItem.route}
                          onChange={(e) =>
                            handleContentChange2(e.target.value, subItem.route)
                          }
                          className="textarea textarea-bordered textarea-md w-full"
                        />
                        <button
                          className="btn btn-wide self-center hover:bg-customPink hover:text-white"
                          onClick={() => handleDelete(idx, subIdx)}
                        >
                          Delete
                        </button>
                        <button
                          className="btn btn-wide self-center hover:bg-customPink hover:text-white"
                          onClick={onSave}
                        >
                          Save
                        </button>
                      </div>
                    );
                  })}
                </div>
              );
            })}

            <button
              className="btn btn-wide self-center hover:bg-customPink hover:text-white"
              onClick={() => handleAdd()}
            >
              Add
            </button>

            <button
              className="btn btn-wide self-center hover:bg-customPink hover:text-white"
              onClick={onSave}
            >
              Save
            </button>
          </div>
        );
        return content;
      } catch (e) {
        return <>wait</>;
      }
    }

    case "footer": {
      function handleContentChange(lang, value, route, idx, subIdx = null) {
        const updatedNavlinks = [...data.content];
        if (subIdx !== null) {
          // Update sub-item title
          updatedNavlinks[idx].links[subIdx].title[lang] = value;
        } else {
          // Update main item title
          updatedNavlinks[idx].title[lang] = value;
        }
        setData({ ...data, content: updatedNavlinks });
        onSave();
      }

      function handleDelete(idx, subIdx = null) {
        const updatedNavlinks = [...data.content];
        if (subIdx !== null) {
          // Remove sub-item
          updatedNavlinks[idx].links.splice(subIdx, 1);
        } else {
          // Remove main item
          updatedNavlinks.splice(idx, 1);
        }
        setData({ ...data, content: updatedNavlinks });
        onSave();
      }

      function handleAdd(idx = null) {
        const updatedNavlinks = [...data.content];
        if (idx !== null) {
          // Add a new sub-item
          updatedNavlinks[idx].links.push({
            title: { ar: "sample", en: "sample", tr: "sample" },
            route: "/",
          });
        } else {
          // Add a new main item
          updatedNavlinks.push({
            title: { ar: "sample", en: "sample", tr: "sample" },
            links: [
              {
                title: { ar: "sample", en: "sample", tr: "sample" },
                route: "/",
              },
            ],
          });
        }
        setData({ ...data, content: updatedNavlinks });
        onSave();
      }

      try {
        content = (
          <div className="m-3">
            <h1>Footer</h1>

            {data.content.map((itm, idx) => (
              <div
                key={idx}
                className="m-2 shadow-lg bg-white p-5 space-y-3 rounded-md"
              >
                <h1>Arabic</h1>
                <input
                  value={itm.title.ar}
                  onChange={(e) =>
                    handleContentChange("ar", e.target.value, itm.route, idx)
                  }
                  className="textarea textarea-bordered textarea-md w-full"
                />

                <h1>English</h1>
                <input
                  value={itm.title.en}
                  onChange={(e) =>
                    handleContentChange("en", e.target.value, itm.route, idx)
                  }
                  className="textarea textarea-bordered textarea-md w-full"
                />

                <h1>Turkish</h1>
                <input
                  value={itm.title.tr}
                  onChange={(e) =>
                    handleContentChange("tr", e.target.value, itm.route, idx)
                  }
                  className="textarea textarea-bordered textarea-md w-full"
                />

                <button
                  className="btn btn-wide self-center hover:bg-customPink hover:text-white"
                  onClick={() => handleDelete(idx)}
                >
                  Delete
                </button>

                <button
                  className="btn btn-wide self-center hover:bg-customPink hover:text-white"
                  onClick={() => handleAdd(idx)}
                >
                  Add Sub-Link
                </button>

                {itm.links.map((subItem, subIdx) => (
                  <div
                    key={subIdx}
                    className="m-2 shadow-lg bg-gray-200 p-5 space-y-3 rounded-md"
                  >
                    <h1>Arabic</h1>
                    <input
                      value={subItem.title.ar}
                      onChange={(e) =>
                        handleContentChange(
                          "ar",
                          e.target.value,
                          subItem.route,
                          idx,
                          subIdx
                        )
                      }
                      className="textarea textarea-bordered textarea-md w-full"
                    />

                    <h1>English</h1>
                    <input
                      value={subItem.title.en}
                      onChange={(e) =>
                        handleContentChange(
                          "en",
                          e.target.value,
                          subItem.route,
                          idx,
                          subIdx
                        )
                      }
                      className="textarea textarea-bordered textarea-md w-full"
                    />

                    <h1>Turkish</h1>
                    <input
                      value={subItem.title.tr}
                      onChange={(e) =>
                        handleContentChange(
                          "tr",
                          e.target.value,
                          subItem.route,
                          idx,
                          subIdx
                        )
                      }
                      className="textarea textarea-bordered textarea-md w-full"
                    />

                    <h1>Route</h1>
                    <input
                      value={subItem.route}
                      onChange={(e) =>
                        handleContentChange(
                          "route",
                          e.target.value,
                          subItem.route,
                          idx,
                          subIdx
                        )
                      }
                      className="textarea textarea-bordered textarea-md w-full"
                    />

                    <button
                      className="btn btn-wide self-center hover:bg-customPink hover:text-white"
                      onClick={() => handleDelete(idx, subIdx)}
                    >
                      Delete Sub-Link
                    </button>
                  </div>
                ))}
              </div>
            ))}

            <button
              className="btn btn-wide self-center hover:bg-customPink hover:text-white"
              onClick={() => handleAdd()}
            >
              Add New Link Group
            </button>

            <button
              className="btn btn-wide self-center hover:bg-customPink hover:text-white"
              onClick={onSave}
            >
              Save
            </button>
          </div>
        );
        return content;
      } catch (e) {
        return <>wait</>;
      }
    }

    case "volunteers": {
      function createHeaders(keys) {
        return keys.map((key) => ({
          id: key,
          name: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize header names
          prompt: key,
          width: 65,
          align: "center",
          padding: 0,
        }));
      }

      function onDownload(item) {
        const doc = new jsPDF({
          putOnlyUsedFonts: true,
          orientation: "landscape",
        });

        const headers = createHeaders(["Field", "Value"]);

        const dataPDF = [
          { Field: "Full Name", Value: `${item.firstName} ${item.lastName}` },
          { Field: "Birthdate", Value: item.Birthdate },
          { Field: "Country", Value: item.Country },
          { Field: "City", Value: item.City },
          { Field: "Email", Value: item.email },
          { Field: "Address", Value: item.Address },
          { Field: "Phone", Value: item.Phone },
          { Field: "Current Job", Value: item.currentJob },
          { Field: "Education", Value: item.education },
          { Field: "Person 1", Value: item.person1 },
          { Field: "Person 2", Value: item.person2 },
          { Field: "Volunteering Type", Value: item.type },
        ];

        doc.text("Aqsa Kadinlari - Volunteer Details", 14, 16);

        // Generate table using jsPDF-AutoTable
        doc.autoTable({
          startY: 20,
          head: [headers.map((header) => header.name)],
          body: dataPDF.map((row) => [row.Field, row.Value]),
        });

        doc.save(`volunteer_${item.firstName}.pdf`);
      }

      try {
        return (
          <div className="m-3">
            <h1>Volunteers</h1>
            <div className="overflow-x-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Job</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {data.body.map((item, idx) => (
                    <tr key={idx}>
                      <th>{idx + 1}</th>
                      <td>
                        {item.firstName} {item.lastName}
                      </td>
                      <td>{item.currentJob}</td>
                      <td>
                        <button
                          className="btn"
                          onClick={() => onDownload(item)}
                        >
                          Download
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      } catch (e) {
        return <>wait</>;
      }
    }

    // implement the said that logic
    case "saidthat": {
      content = (
        <div className="m-3">
          <h1>Said About US</h1>
          {data?.body && data.body.length > 0 ? (
            data.body.map((item, index) => (
              <div key={index} className="m-5 space-y-3">
                <div className="p-5 rounded-lg bg-gray-400 shadow-lg">
                  {/* Images for different languages */}
                  <img
                    src={item.img?.en}
                    className="rounded-lg"
                    alt="English image"
                  />

                  <h1 className="text-2xl font-bold text-customOrange">
                    Title in English
                  </h1>
                  {/* Title in a specific language */}
                  <h1 className="text-xl font-bold text-customPink">
                    {item.title?.en || ""}{" "}
                    {/* Adjust the language here as needed */}
                  </h1>

                  <h1 className="text-2xl font-bold text-customOrange">
                    Title in Arabic
                  </h1>
                  {/* Title in a specific language */}
                  <h1 className="text-xl font-bold text-customPink">
                    {item.title?.ar || ""}{" "}
                    {/* Adjust the language here as needed */}
                  </h1>

                  <h1 className="text-2xl font-bold text-customOrange">
                    Title in Turkis
                  </h1>
                  {/* Title in a specific language */}
                  <h1 className="text-xl font-bold text-customPink">
                    {item.title?.tr || ""}{" "}
                    {/* Adjust the language here as needed */}
                  </h1>

                  <br></br>
                  <br></br>
                  <br></br>

                  <h1 className="text-2xl font-bold text-customOrange">
                    description in English
                  </h1>
                  {/* Title in a specific language */}
                  <h1 className="text-xl font-bold text-customPink">
                    {item.details?.en || ""}{" "}
                    {/* Adjust the language here as needed */}
                  </h1>

                  <h1 className="text-2xl font-bold text-customOrange">
                    description in Arabic
                  </h1>
                  {/* Title in a specific language */}
                  <h1 className="text-xl font-bold text-customPink">
                    {item.details?.ar || ""}{" "}
                    {/* Adjust the language here as needed */}
                  </h1>

                  <h1 className="text-2xl font-bold text-customOrange">
                    description in Turkish
                  </h1>
                  {/* Title in a specific language */}
                  <h1 className="text-xl font-bold text-customPink">
                    {item.details?.tr || ""}{" "}
                    {/* Adjust the language here as needed */}
                  </h1>
                </div>
                <button className="btn" onClick={() => onDelete(item.id)}>
                  Remove
                </button>
              </div>
            ))
          ) : (
            <p>No items available</p>
          )}

          <NewItem>
            {/* Input fields for each language-specific image */}
            <input
              type="text"
              placeholder="Image Path for English"
              className="input w-full max-w-xs mt-2"
              value={SaidThatItem.img?.en || ""}
              onChange={(e) =>
                setSaidThatItem({
                  ...SaidThatItem,
                  img: { ...SaidThatItem.img, en: e.target.value },
                })
              }
            />

            {/* File Uploaders for each image */}
            <FileUploader
              onFileLoaded={(base64) =>
                setSaidThatItem({
                  ...SaidThatItem,
                  img: { ...SaidThatItem.img, en: base64 },
                })
              }
            />

            {/* Title and Details */}
            <input
              type="text"
              placeholder="Title in Arabic"
              className="input w-full max-w-xs mt-2"
              value={SaidThatItem.title.ar || ""}
              onChange={(e) =>
                // setSaidThatItem({
                //   ...SaidThatItem,
                //   title: { ...SaidThatItem.title, ar: e.target.value },
                // })
                setSaidThatItem({
                  ...SaidThatItem,
                  title: { ...SaidThatItem.title, ar: e.target.value },
                })
              }
            />

            <input
              type="text"
              placeholder="Title in English"
              className="input w-full max-w-xs mt-2"
              value={SaidThatItem.title.en || ""}
              onChange={(e) =>
                setSaidThatItem({
                  ...SaidThatItem,
                  title: { ...SaidThatItem.title, en: e.target.value },
                })
              }
            />

            <input
              type="text"
              placeholder="Title in Turkish"
              className="input w-full max-w-xs mt-2"
              value={SaidThatItem.title.tr || ""}
              onChange={(e) =>
                setSaidThatItem({
                  ...SaidThatItem,
                  title: { ...SaidThatItem.title, tr: e.target.value },
                })
              }
            />

            <input
              type="text"
              placeholder="Details in arabic"
              className="input w-full max-w-xs mt-2"
              value={SaidThatItem.details.ar || ""}
              onChange={(e) =>
                setSaidThatItem({
                  ...SaidThatItem,
                  details: { ...SaidThatItem.details, ar: e.target.value },
                })
              }
            />

            <input
              type="text"
              placeholder="Details in english"
              className="input w-full max-w-xs mt-2"
              value={SaidThatItem.details.en || ""}
              onChange={(e) =>
                setSaidThatItem({
                  ...SaidThatItem,
                  details: { ...SaidThatItem.details, en: e.target.value },
                })
              }
            />

            <input
              type="text"
              placeholder="Details in turkih"
              className="input w-full max-w-xs mt-2"
              value={SaidThatItem.details.tr || ""}
              onChange={(e) =>
                setSaidThatItem({
                  ...SaidThatItem,
                  details: { ...SaidThatItem.details, tr: e.target.value },
                })
              }
            />

            <input
              type="text"
              placeholder="video url in embedded mode"
              className="input w-full max-w-xs mt-2"
              value={SaidThatItem.video || ""}
              onChange={(e) =>
                setSaidThatItem({ ...SaidThatItem, video: e.target.value })
              }
            />

            {/* Buttons */}
            <button className="btn mt-2" onClick={() => onAdd(SaidThatItem)}>
              Save
            </button>
            <button className="btn mt-2" onClick={closeModal}>
              Cancel
            </button>
          </NewItem>
        </div>
      );
      return content;
    }

    default:
      return null;
  }
}

export default HomeEditorCTranslator;
