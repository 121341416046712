import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import api from "../../api.json";
import React, { useEffect, useState, useRef } from "react";
import { useSpring, animated } from '@react-spring/web';

function PromotionFilms() {
  const [myCollection, setMyCollection] = useState([]); // Ensure it's initialized
  const [isInView, setIsInView] = useState(false);

  const divRef = useRef(); // Create a ref for the animated div

  const springs = useSpring({
    from: { y: 500 },
    to: { y: isInView ? 0 : 500 }, // Only animate when the div is in view
    // delay: 500,
  });
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.unobserve(entry.target); // Stop observing once the animation is triggered
          }
        });
      },
      { threshold: 0.1 } // Adjust this value to control when the animation should trigger
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const fetchPromotionVideos = async () => {
      try {
        const response = await fetch(`${api.url}/get/promotionFilms`);
        const data = await response.json();
        console.log("API Response:", data); // Log the response to debug
        if (data && data.body) {
          setMyCollection(data.body); // Ensure you're accessing the correct property
        } else {
          console.error("Unexpected API response structure", data);
        }
      } catch (error) {
        console.error("Error fetching promotion videos:", error);
      }
    };

    fetchPromotionVideos();
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
  };

  const settings3 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const { t } = useTranslation();

  return (
    <animated.div ref={divRef} 
      className="bg-cover bg-center p-6"
      style={{
        backgroundImage: `url('/images/kampanya_bg.png')`,...springs
      }}
    >
      <div className="hidden md:block lg:block m-5">
        <div className="flex flex-col items-center container">
          <h1 className="card-title py-2 text-3xl font-bold text-customPink">
            {t("promotionVideos.title")}
          </h1>
          <div className="flex flex-col container space-x-5 space-y-5">
            <Slider {...settings}>
              {myCollection.map((card, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center p-2 rounded-md mr-5"
                >
                  <iframe
                    src={card.src}
                    className="w-full"
                    style={{ minHeight: 200, borderRadius: "15px" }}
                    title={`Promotion Video ${index}`}
                  />
                  <div className="card-body flex items-center justify-center">
                    <h2 className="card-title text-customOrange">{card.title}</h2>
                    <p className="text-gray-500">{card.details}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className="block lg:hidden md:hidden sm:block my-5">
        <div className="flex flex-col items-center container">
          <h1 className="card-title my-2 font-bold text-3xl text-customPink">
            {t("promotionVideos.title")}
          </h1>
          <div className="container">
            <Slider {...settings3}>
              {myCollection.map((card, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center p-2 rounded-md mr-5"
                >
                  <iframe
                    src={card.src}
                    className="w-full"
                    style={{ minHeight: 200, borderRadius: "15px" }}
                    title={`Promotion Video ${index}`}
                  />
                  <div className="card-body flex items-center justify-center">
                    <h2 className="card-title">{card.title}</h2>
                    <p>{card.details}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </animated.div >
  );
}

export default PromotionFilms;

// const MyCollection = [
//   {
//     title: "First card",
//     details: "dtails",
//     src: "https://www.youtube.com/embed/jDyrvGFqWDI",
//   },
//   {
//     title: "Second card",
//     details: "dtails",
//     src: "https://www.youtube.com/embed/Fw8qjiraCMw",
//   },
// ];