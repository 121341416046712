import React, { useEffect, useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  faMobileScreen,
  faCircleCheck,
  faBuildingColumns,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LanguageIcon from "@mui/icons-material/Language";
import api from "../../api.json";

function Banner({ trsndlateProvider, i18n }) {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch(`${api.url}/get/header`);
        const data = await response.json();
        setConfig(data);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    fetchConfig();
  }, []);

  if (!config) {
    return <div></div>; // Optionally show a loading state while fetching data
  }

  const changeLanguageHandler = (lang) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
  };

  const dir = trsndlateProvider("dir");
  const endAlignmentClass = dir === "rtl" ? "mr-auto" : "ml-auto";
  const space = dir === "rtl" ? "mr-4" : "ml-4";
  const space2 = dir === "rtl" ? "mr-2" : "ml-2";

  return (
    <div
      style={{
        backgroundColor: "#dedcdc",
        direction: trsndlateProvider("dir"),
      }}
    >
      <div className="flex justify-items-stretch justify-between items-center p-3 container">
        {/* phone number */}
        <span className={`flex items-center ${space}`}>
          <FontAwesomeIcon icon={faMobileScreen} />
          <a
            href={`https://wa.me/90${
              config.banner1.phone.title[trsndlateProvider("lang")]
            }`}
            target="_blank"
            className={`${space2}`}
            style={{ textDecoration: "none" }}
          >
            0
            {trsndlateProvider(
              config.banner1.phone.title[trsndlateProvider("lang")]
            )}
          </a>
        </span>

        {/* follow us */}
        <span className={`dropdown dropdown-hover flex ${space}`}>
          <div tabIndex={0} className="m-1 pe-1 flex items-center">
            <FontAwesomeIcon icon={faCircleCheck} />
            <span className={`${space2} font-mono`}>
              {trsndlateProvider(
                config.banner1.followus.title[trsndlateProvider("lang")]
              )}
            </span>
          </div>

          <ul
            tabIndex={0}
            className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
          >
            <li>
              <a
                href={config.banner1.followus.social.facebook}
                style={{ textDecoration: "none" }}
              >
                <span>
                  <FacebookIcon />
                </span>
                Facebook
              </a>
            </li>
            <li>
              <a
                href={config.banner1.followus.social.instagram}
                style={{ textDecoration: "none" }}
              >
                <span>
                  <InstagramIcon />
                </span>
                Instagram
              </a>
            </li>
            <li>
              <a
                href={config.banner1.followus.social.youtube}
                style={{ textDecoration: "none" }}
              >
                <span>
                  <YouTubeIcon />
                </span>
                Youtube
              </a>
            </li>
          </ul>
        </span>

        {/* Bank accounts */}
        <span className={`flex items-center ${space} font-mono`}>
          <FontAwesomeIcon icon={faBuildingColumns} />
          <a
            href={config.banner1.bank.route}
            target="_blank"
            className={`${space2}`}
            style={{ textDecoration: "none" }}
          >
            {trsndlateProvider(
              config.banner1.bank.title[trsndlateProvider("lang")]
            )}
          </a>
        </span>

        {/* Language dropdown */}
        <span
          className={`flex items-center font-mono justify-self-end ${endAlignmentClass}`}
        >
          <span className="dropdown dropdown-hover dropdown-left">
            <div tabIndex={0} className="m-1 pe-1 flex items-center">
              <FontAwesomeIcon icon={faGlobe} />
              <a
                href="#"
                style={{ textTransform: "uppercase", textDecoration: "none" }}
                className={`${space2}`}
              >
                {i18n.language}
              </a>
            </div>

            <ul
              tabIndex={0}
              className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
            >
              <li>
                <a
                  style={{ textDecoration: "none" }}
                  href="#"
                  onClick={() => {
                    changeLanguageHandler("tr");
                  }}
                >
                  Türkçe
                </a>
              </li>
              <li>
                <a
                  href="#"
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    changeLanguageHandler("en");
                  }}
                >
                  English
                </a>
              </li>
              <li>
                <a
                  style={{ textDecoration: "none" }}
                  href="#"
                  onClick={() => {
                    changeLanguageHandler("ar");
                  }}
                >
                  العربية
                </a>
              </li>
            </ul>
          </span>
        </span>
      </div>
    </div>
  );
}

function BannerMobile({ trsndlateProvider, i18n, children }) {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch(`${api.url}/get/header`);
        const data = await response.json();
        setConfig(data);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    fetchConfig();
  }, []);

  if (!config) {
    return <div></div>; // Optionally show a loading state while fetching data
  }

  const changeLanguageHandler = (lang) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
  };

  const dir = trsndlateProvider("dir");
  const endAlignmentClass = dir === "rtl" ? "mr-auto" : "ml-auto";
  const space = dir === "rtl" ? "mr-2" : "ml-2";
  const space2 = dir === "rtl" ? "mr-2" : "ml-2";

  return (
    <div className="drawer z-40">
      <input
        id="my-drawer-3"
        type="checkbox"
        className="drawer-toggle my-auto h-full"
      />

      <div className="drawer-content flex flex-col">
        {/* Navbar */}

        <div
          className="navbar w-full"
          style={{ direction: dir === "rtl" ? "ltr" : "rtl" }}
        >
          <div className="flex-none lg:hidden">
            <label
              htmlFor="my-drawer-3"
              aria-label="open sidebar"
              className="btn btn-square btn-ghost"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block h-6 w-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </label>
          </div>

          <div className="flex me-auto my-auto">
            {/* phone number */}
            <span className={`flex items-center ${space}`}>
              <a
                href={`https://wa.me/90${
                 config.banner1.phone.title[trsndlateProvider("lang")]
                }`}
                target="_blank"
                rel="noopener noreferrer"
                className={`${space2}`}
              >
                <WhatsAppIcon />
              </a>
            </span>

            {/* Bank accounts */}
            <span className={`flex items-center ${space}`}>
              <a
                href={config.banner1.bank.route}
                target="_blank"
                rel="noopener noreferrer"
                className={`${space2}`}
              >
                <AccountBalanceIcon />
              </a>
            </span>

            {/* Language dropdown */}
            <span className={`flex items-center ${endAlignmentClass}`}>
              <div className="dropdown dropdown-hover">
                <div tabIndex={0} className="m-1 pe-1 flex items-center">
                  <a
                    href="#"
                    style={{ textTransform: "uppercase" }}
                    className={`${space2}`}
                  >
                    <LanguageIcon />
                  </a>
                </div>

                <ul
                  tabIndex={0}
                  className="dropdown-content  menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
                >
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        changeLanguageHandler("tr");
                      }}
                    >
                      Türkçe
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        changeLanguageHandler("en");
                      }}
                    >
                      English
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        changeLanguageHandler("ar");
                      }}
                    >
                      العربية
                    </a>
                  </li>
                </ul>
              </div>
            </span>
          </div>

          {/* logo */}
          <a href="/">
            <img
              src={"/images/logo_" + i18n.language + ".svg"}
              style={{ maxWidth: 120, maxHeight: 80 }}
              alt="Logo"
            />
          </a>
        </div>

      </div>

      {/* Sidebar */}
      <div className="drawer-side">
        <label
          htmlFor="my-drawer-3"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        {children}

        {/* <ul className="menu w-80 bg-base-100 p-4">
          <li>
            <a href="#" className="text-xl text-gray-700">
              Item 1
            </a>
          </li>
          <li>
            <a href="#" className="text-xl text-gray-700">
              Item 2
            </a>
          </li>
          <li>
            <a href="#" className="text-xl text-gray-700">
              Item 3
            </a>
          </li>
        </ul> */}
      </div>
    </div>
  );
}

export { Banner, BannerMobile };
