import React, { useState, useEffect } from "react";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import AddBox from "@mui/icons-material/AddBox";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import api from "../../api.json";

function ProjectPageCreator({ pages, setPages }) {
  const [pageName, setPageName] = useState("");

  const pageData = {
    title: {
      ar: " عنوان الصفحة",
      en: " Page Title ",
      tr: "Page Title in turkish",
    },
    category: "general",
    body: [],
  };
  const handleSave = async () => {
    try {
      const response = await fetch(`${api.url}/set/${pageName}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(pageData),
      });

      if (response.ok) {
        console.log("Page created successfully");
        pages.push(pageName);

        const response2 = await fetch(`${api.url}/set/ProjectList`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(pages),
        });

        setPages(pages);

        // Close the modal after saving
        document.getElementById("my_modal_6").checked = false;
      } else {
        console.error("Failed to create page");
      }
    } catch (error) {
      console.error("Error creating page:", error);
    }
  };

  return (
    <>
      <label htmlFor="my_modal_6" className="btn">
        <AddBox />
        <p className="text-sm">Add Page</p>
      </label>
      <input type="checkbox" id="my_modal_6" className="modal-toggle" />

      <div className="modal" role="dialog">
        <div className="modal-box">
          <h3 className="text-lg font-bold m-2">Create Page</h3>
          <input
            type="text"
            placeholder="Page Name Here"
            className="input input-bordered w-full max-w-xs m-2"
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
          />
          <div className="modal-action">
            <button htmlFor="my_modal_6" onClick={handleSave} className="btn">
              <BookmarkAddedIcon />
              <p className="text-sm">Save</p>
            </button>
            <label htmlFor="my_modal_6" className="btn">
              Close!
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

function AdminSideBar({ onButtonClick }) {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${api.url}/get/ProjectList`);
        const data = await response.json();
        setProjects(data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects(); // Fetch initially

    const interval = setInterval(() => {
      fetchProjects(); // Fetch every 5 seconds
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-center bg-gray-200 shadow-md min-h-screen overflow-y-scroll">
      <img className="flex space-y-4" width={150} src="/images/logo_en.svg" />
      <ProjectPageCreator pages={projects} setPages={setProjects} />

      <button
        className="btn btn-wide mt-2 justify-start ps-2 text-amber-950 hover:text-customPink"
        onClick={() => onButtonClick("/", "header")}
      >
        <HomeIcon />
        Header
      </button>

      <button
        className="btn btn-wide mt-2 justify-start ps-2 text-amber-950 hover:text-customPink"
        onClick={() => onButtonClick("/", "footer")}
      >
        <HomeIcon />
        Footer
      </button> 

      <button
        className="btn btn-wide mt-2 justify-start ps-2 text-amber-950 hover:text-customPink"
        onClick={() => onButtonClick("/", "cursoul")}
      >
        <HomeIcon />
        Home Cursoul
      </button>

      <button
        className="btn btn-wide mt-2 justify-start ps-2 text-amber-950 hover:text-customPink"
        onClick={() => onButtonClick("/", "campaigns")}
      >
        <HomeIcon />
        Home Campaigns
      </button>

      <button
        className="btn btn-wide mt-2 justify-start ps-2 text-amber-950 hover:text-customPink"
        onClick={() => onButtonClick("/", "promotionFilms")}
      >
        <HomeIcon />
        Home Promotion Films
      </button>

      <button
        className="btn btn-wide mt-2 justify-start ps-2 text-amber-950 hover:text-customPink"
        onClick={() => onButtonClick("/", "saidthat")}
      >
        <HomeIcon />
        Told That
      </button>

      <button
        className="btn btn-wide mt-2 justify-start ps-2 text-amber-950 hover:text-customPink"
        onClick={() => onButtonClick("/#/projects", "projects")}
      >
        <AdUnitsIcon />
        Projects
      </button>

      {/* Render fetched project buttons */}
      {projects
        ? projects.map((project) => (
            <button
              className="btn btn-wide mt-2 justify-start ps-2 text-amber-950 hover:text-customPink"
              onClick={() => onButtonClick("/#/project?p=" + project, project)}
            >
              <HomeIcon />
              {project}
            </button>
          ))
        : null}

      <button
        className="btn btn-wide mt-2 justify-start ps-2 text-amber-950 hover:text-customPink"
        onClick={() => onButtonClick("/#/aboutus", "aboutus")}
      >
        <InfoIcon />
        About Us
      </button>
      <button
        className="btn btn-wide mt-2 justify-start ps-2 text-amber-950 hover:text-customPink"
        onClick={() => onButtonClick("/#/events", "events")}
      >
        <EditCalendarIcon />
        Conferences
      </button>
      <button
        className="btn btn-wide mt-2 justify-start ps-2 text-amber-950 hover:text-customPink"
        onClick={() => onButtonClick("/#/presedentword", "presedentword")}
      >
        <PermContactCalendarIcon />
        President's Word
      </button>
      <button
        className="btn btn-wide mt-2 justify-start ps-2 text-amber-950 hover:text-customPink"
        onClick={() => onButtonClick("/#/contactus", "contactus")}
      >
        <RecordVoiceOverIcon />
        Contact Us
      </button>
      <button
        className="btn btn-wide mt-2 justify-start ps-2 text-amber-950 hover:text-customPink"
        onClick={() => onButtonClick("/#/policies", "policies")}
      >
        <CalendarViewDayIcon />
        Policies
      </button>

      <button
        className="btn btn-wide mt-2 justify-start ps-2 text-amber-950 hover:text-customPink"
        onClick={() => onButtonClick("/", "volunteers")}
      >
        <CalendarViewDayIcon />
        Volunteers
      </button>
    </div>
  );
}

export default AdminSideBar;
