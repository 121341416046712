import "./App.scss";
import Home from "./Pages/Home/Home";
import BankAccounts from "./Pages/BankAccounts/BankAccounts";
import AboutUS from "./Pages/AboutUs/AboutUs";
import Projects from "./Pages/projects/projects";
import ProjectDetail from "./Pages/projectDetails/projectDetails"; // Updated import path
import ContactUS from "./Pages/contactUs/contactus";
import Presedentword from "./Pages/presedentWord/presedentword";
import Policies from "./Pages/policies/Policies";
import Events from "./Pages/events/events";
import Admin from "./Pages/Admin/Admin";
import Volunteers from "./Pages/volunteers/volunteers";

const AppRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/admin",
    element: <Admin />,
  },
  {
    path: "/bank-accounts",
    element: <BankAccounts />,
  },
  {
    path: "/aboutus",
    element: <AboutUS />,
  },
  {
    path: "/projects",
    element: <Projects />
  },
  {
    path: "/project", // This creates the /projects/:hello route
    element: <ProjectDetail />,

  },
  {
    path: "/contactus",
    element: <ContactUS />,
  },
  {
    path: "/presedentword",
    element: <Presedentword />,
  },
  {
    path: "/policies",
    element: <Policies />,
  },
  {
    path: "/events",
    element: <Events />,
  },
  {
    path: "/volunteers",
    element: <Volunteers />,
  },
];

export default AppRoutes;
