function CampaginCard({ title, details, img }) {
  return (
    <div class="card    shadow-xl m-2"
    style={{minWidth:250,height:400}}
    >
      <figure>
        <img
          src={img}
          alt="card-image"
        />
      </figure>
      <div class="card-body">
        <h2 class="card-title">{title}</h2>
        <p>{details}</p>
        <div class="card-actions justify-start">
          <button class="btn btn-primary  bg-customPink text-white ">donate</button>
        </div>
      </div>
    </div>
  );
}
export default CampaginCard;
