import React, { useEffect, useState, useRef } from "react";
import { useSpring, animated } from '@react-spring/web';
import { useTranslation } from 'react-i18next';
import api from "../../api.json";

function Statistics() {
  const { t } = useTranslation();
  const [data, setData] = useState({
    projects: 0,
    activeProjects: 0,
    benefited: 0,
    volunteers: 0,
    supported: 0,
  });
  const [isInView, setIsInView] = useState(false);
  const divRef = useRef();

  const springs = useSpring({
    from: { y: 500 },
    to: { y: isInView ? 0 : 500 },
  });

  // Number descending animations
  const projectsSpring = useSpring({
    from: { number: data.projects + 100 }, // Start from a higher value
    to: { number: data.projects },
    config: { duration: 1500 },
    reset: true, // Reset animation when data changes
  });

  const activeProjectsSpring = useSpring({
    from: { number: data.activeProjects + 100 },
    to: { number: data.activeProjects },
    config: { duration: 1500 },
    reset: true,
  });

  const benefitedSpring = useSpring({
    from: { number: data.benefited + 100 },
    to: { number: data.benefited },
    config: { duration: 1500 },
    reset: true,
  });

  const volunteersSpring = useSpring({
    from: { number: data.volunteers + 100 },
    to: { number: data.volunteers },
    config: { duration: 1500 },
    reset: true,
  });

  const supportedSpring = useSpring({
    from: { number: data.supported + 100 },
    to: { number: data.supported },
    config: { duration: 1500 },
    reset: true,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await fetch(`${api.url}/get/statistics`);
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };

    fetchStatistics();
  }, []);

  return (
    <animated.div ref={divRef}
      className="flex items-center justify-center w-screen"
      style={{
        backgroundImage: `url('/images/istatistik-zemin.png')`,
        ...springs
      }}
    >
      <div className="bg-transparent m-5">
        <div className="flex min-w-screen items-center justify-center">
          <h1 className="font-black text-3xl text-customPink">{t('statistics.title')}</h1>
        </div>
        <div
          className="container grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5"
          style={{ minHeight: '40vh' }}
        >
          <div className="stat flex flex-col items-center justify-center bg-transparent" style={{ minHeight: '200px' }}>
            <img src="/images/icon1.png" style={{ width: '50px' }} alt="Icon" />
            <div className="stat-title font-bold">{t('statistics.finishedProjects')}</div>
            <animated.div className="stat-value text-customOrange">
              {projectsSpring.number.to(n => Math.floor(n))}
            </animated.div>
          </div>

          <div className="stat flex flex-col items-center justify-center bg-transparent" style={{ minHeight: '200px' }}>
            <img src="/images/icon2.png" style={{ width: '50px' }} alt="Icon" />
            <div className="stat-title font-bold">{t('statistics.activeProjects')}</div>
            <animated.div className="stat-value text-customOrange">
              {activeProjectsSpring.number.to(n => Math.floor(n))}
            </animated.div>
          </div>

          <div className="stat flex flex-col items-center justify-center bg-transparent" style={{ minHeight: '200px' }}>
            <img src="/images/icon3.png" style={{ width: '50px' }} alt="Icon" />
            <div className="stat-title font-bold">{t('statistics.benefited')}</div>
            <animated.div className="stat-value text-customOrange">
              {benefitedSpring.number.to(n => Math.floor(n))}
            </animated.div>
          </div>

          <div className="stat flex flex-col items-center justify-center bg-transparent" style={{ minHeight: '200px' }}>
            <img src="/images/icon4.png" style={{ width: '50px' }} alt="Icon" />
            <div className="stat-title font-bold">{t('statistics.volunteers')}</div>
            <animated.div className="stat-value text-customOrange">
              {volunteersSpring.number.to(n => Math.floor(n))}
            </animated.div>
          </div>

          <div className="stat flex flex-col items-center justify-center bg-transparent" style={{ minHeight: '200px' }}>
            <img src="/images/icon5.png" style={{ width: '50px' }} alt="Icon" />
            <div className="stat-title font-bold">{t('statistics.supported')}</div>
            <animated.div className="stat-value text-customOrange">
              {supportedSpring.number.to(n => Math.floor(n))}
            </animated.div>
          </div>
        </div>
      </div>
    </animated.div>
  );
}

export default Statistics;

