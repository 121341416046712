import { useTranslation } from "react-i18next";
import React, {  useState } from "react";
import { useFloating } from "@floating-ui/react";

function StickyMobileDonationBTNs() {
  const { t, i18n } = useTranslation();
  const [marginTop, setMarginTop] = useState("80vh");

  const { refs, floatingStyles } = useFloating();

  return (
    <div
      className="hidden fixed col bottom-0"
      style={{
        zIndex: 100,
        width: "100vw",
        height:"100px",
        left: 0,
      }}
    >
      <div
        className=" flex justify-center items-center bg-gray-200 p-4 w-full"
        style={floatingStyles}
        ref={refs.setFloating}
      >
        <button
          className="btn mx-1 text-white"
          style={{ backgroundColor: "#c8158d" }}
        >
          {t("banner2.donate")}
        </button>
        <button className="btn" style={{ backgroundColor: "#f7a01a" }}>
          {t("banner2.basket")}
        </button>
      </div>
    </div>
  );
}

export default StickyMobileDonationBTNs;
