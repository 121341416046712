/**
 * the basic idea is to input a json data which so that the page Generator
 * will generate the ComponentList which will physically displayed inside
 * the page layout.
 *
 */
import React, { Component } from "react";
import CText from "../Components/Text/text";
import CArticle from "../Components/Article/CArticle";
import PageTitle from "../Components/shared/PageTitle";
import { useTranslation } from "react-i18next";
import CardWrapper from "../Components/shared/CardWrapper";
import ProjectCard from "../Components/shared/ProjectCard";
import PeopleDisplayer from "../Components/people/people";
import Timeline from "../Components/timeline/timeline";

/**
 ********************** list of all usable components *********************
 * text
 * ************************************************************************
 *     {
 *          id:"id",
 *          "type":"text",
 *          "color":"",
 *          "hoverColor":"",
 *          "layout":"",
 *          "size":"",
 *          "weight":"",
 *          "isLink":"",
 *          "link":"",
 *          "children":[]
 *      }
 * **************************************************************************
 * simple-article
 * **************************************************************************
 * {
 *          "id":""
 *          "type":"simple-article"
 *          "background":"",
 *          "layout":"",
 *          "spacing":"",
 *          "children":[]
 * }
 * **************************************************************************
 * article-with-img
 * **************************************************************************
 * {
 *          "id":""
 *          "type":"article-with-img"
 *          "background":"",
 *          "img":""
 *          "children":[]
 * }
 * **************************************************************************
 * card-wrapper
 * **************************************************************************
 * {
 *          "id":""
 *          "type":"card-wrapper"
 *          "title":"",
 *          "children":[]
 * }
 * **************************************************************************
 * project-card
 * **************************************************************************
 * {
 *          "id":""
 *          "img":"path"
 *          "type":"project-card"
 *          "title":"",
 *          "hoverColor":"",
 *          "overlayColor":"",
 *          "route":""
 * }
 * **************************************************************************
 * people
 * **************************************************************************
 * {
 *    "id":""
 *    "type":"people"
 *    "people":[
 *                {
 *                  img: "/images/people.webp",
 *                  name: "amr basem in tr",
 *                  country: "Agypten",
 *                  details: "hello proj2",
 *                },
 *             ]
 * }
 * * **************************************************************************
 * timeline
 * **************************************************************************
 * {
 *    "id":""
 *    "type":"timeline"
 *    "title":"",
 *    "data":[
 *                "","",""
 *             ]
 * }
 * @param {*} item
 * @returns
 */

function PageGenerator({ pageContent }) {
  const {t,il8n}=useTranslation();
  function translateItem(item) {
    switch (item.type) {
      case "text": {
        return (
          <CText
            id={item.id}
            color={item.color}
            hoverColor={item.hoverColor}
            layout={item.layout}
            size={item.size}
            weight={item.weight}
            isLink={item.isLink ? true : false}
            link={item.link}
            content={item.content}
          >
            {/* {console.log("item.content text", item.content)} */}
            {/* {console.log("item.content", )} */}
            {/* {item.content ?  : null} */}
            {item.children && item.children.length > 0 && (
              <>
                {item.children.map((child, index) => (
                  <React.Fragment key={index}>
                    {translateItem(child)}
                  </React.Fragment>
                ))}
              </>
            )}
          </CText>
        );
      }
      case "simple-article": {
        return (
          <CArticle
            background={item.background}
            isImage={false}
            layout={item.layout}
            spacing={item.spacing}
          >
            {item.children && item.children.length > 0 && (
              <>
                {item.children.map((child, index) => (
                  <React.Fragment key={index}>
                    {translateItem(child)}
                  </React.Fragment>
                ))}
              </>
            )}
          </CArticle>
        );
      }
      case "article-with-img": {
        return (
          <CArticle
            background={item.background}
            isImage={true}
            img={item.img[t("lang")]}
            layout={item.layout}
            spacing={item.spacing}
          >
            {item.children && item.children.length > 0 && (
              <>
                {item.children.map((child, index) => (
                  <React.Fragment key={index}>
                    {translateItem(child)}
                  </React.Fragment>
                ))}
              </>
            )}
          </CArticle>
        );
      }
      case "card-wrapper": {
        return (
          <CardWrapper title={item.title[t("lang")]}>
            {item.children && item.children.length > 0 && (
              <>
                {item.children.map((child, index) => (
                  <React.Fragment key={index}>
                    {translateItem(child)}
                  </React.Fragment>
                ))}
              </>
            )}
          </CardWrapper>
        );
      }
      case "project-card": {
        return (
          <ProjectCard
            img={item.img[t("lang")]}
            title={item.title[t("lang")]}
            hoverColor={item.hoverColor}
            overlayColor={item.overlayColor}
            route={item.route}
          />
        );
      }
      case "people": {
        return (
          <PeopleDisplayer
            headerTitle1={{ ar: "الاسم", en: "name", tr: "isim" }}
            headerTitle2={{ ar: "الوطيفة", en: "position", tr: "İş" }}
            people={item.people}
          />
        );
      }
      case "timeline": {
        return <Timeline title={item.title} data={item.data} />;
      }
      default: {
        // Handle unexpected item types
        console.warn(`Unknown item type: ${item.type}`);
        return null;
      }
    }
  }

  function homePage(content) {
    return [];
  }

  function generalPage(title, content) {
    var componentList = [<PageTitle title={title} />];
    content.map((child, len) => {
      componentList.push(translateItem(child));
    });

    console.log(componentList);
    return componentList;
  }

  try {
    if (pageContent.category === "home") {
      return homePage;
    } else {
      return generalPage(pageContent.title, pageContent.body);
    }
  } catch (e) {
    console.log(e);
  }
}
export default PageGenerator;
