import { useTranslation } from "react-i18next";


function Timeline({ title, data }) {
  const { t, i18n } = useTranslation();
  const lang = t("lang");
    return (
      <div
        id="targets"
        className="flex flex-col p-5 justify-center items-center"
        style={{ backgroundImage: `url('/images/kampanya_bg.png')` }}
      >
        <h1 className="flex font-bold text-center mt-3 text-3xl text-customPink">
        {title[lang]}
        </h1>
  
        <ul className="timeline mt-2 text-customPink timeline-snap-icon max-md:timeline-compact timeline-vertical">
          {data.map((item, index) => (
            index % 2 === 0 ? (
              <li key={index}>
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="timeline-start mb-10 md:text-end">
                  <div className="text-lg font-black">
                    <h1 className="text-customOrange">{item[lang]}</h1>
                  </div>
                </div>
                <hr />
              </li>
            ) : (
              <li key={index}>
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="timeline-end mb-10 md:text-end">
                  <div className="text-lg font-black">
                    <h1 className="text-customOrange">{item[lang]}</h1>
                  </div>
                </div>
                <hr />
              </li>
            )
          ))}
        </ul>
      </div>
    );
  }
  
  export default Timeline;
  